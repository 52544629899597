import { useState } from "react";
import { Routes, Outlet } from "react-router-dom";
import Topbar from "../Common/Topbar/Topbar";
import Sidebar from "../Common/Sidebar/Sidebar";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../../theme";


export const tabs =[
  { title: "Blood Donors", redirect: "/dashboard/NGO/blood-donors" },
  { title: "Donors List", redirect: "/dashboard/NGO/donors-list" },
  { title: "Organ Donors", redirect: "/dashboard/NGO/organ-donors" },
];

function NgoDash() {
  const [theme, colorMode] = useMode();
  const [setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="Ngo">
          <Sidebar tabs={tabs} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              {/* <Route path="/" element={<Dashboard />} /> */}
            </Routes>
            <div>
              <Outlet />
              {"welcome to NGO Dashboard"}
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default NgoDash;
