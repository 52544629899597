// mockdataRedeem.js
const mockData = [
    {
        id: 1,
        patientName: "Alice Johnson",
        dob: "1990-05-22",
        contact: "555-1234",
        referringDoctor: "Dr. Emily Clarke",
        reason: "Routine check-up",
        specialist: "Dr. Richard Brown",
        dateOfReferral: "2024-09-10",
        notes: "Patient needs follow-up on recent tests."
    },
    {
        id: 2,
        patientName: "Bob Smith",
        dob: "1985-03-15",
        contact: "555-5678",
        referringDoctor: "Dr. Michael Davis",
        reason: "Specialist consultation",
        specialist: "Dr. Susan Lee",
        dateOfReferral: "2024-09-09",
        notes: "Urgent consultation required."
    },
    {
        id: 3,
        patientName: "Carol White",
        dob: "1975-11-30",
        contact: "555-8765",
        referringDoctor: "Dr. Sarah Johnson",
        reason: "Surgical evaluation",
        specialist: "Dr. John Doe",
        dateOfReferral: "2024-09-08",
        notes: "Scheduled for surgery next week."
    },
    {
        id: 4,
        patientName: "David Brown",
        dob: "1980-01-25",
        contact: "555-4321",
        referringDoctor: "Dr. Laura Wilson",
        reason: "Chronic illness management",
        specialist: "Dr. Jane Smith",
        dateOfReferral: "2024-09-07",
        notes: "Needs long-term management plan."
    },
    {
        id: 5,
        patientName: "Eva Green",
        dob: "1992-06-12",
        contact: "555-3456",
        referringDoctor: "Dr. William Lee",
        reason: "Diagnostic tests",
        specialist: "Dr. Alice Brown",
        dateOfReferral: "2024-09-06",
        notes: "Tests required for diagnosing rare condition."
    },
    {
        id: 6,
        patientName: "Frank Moore",
        dob: "1968-10-08",
        contact: "555-6543",
        referringDoctor: "Dr. Daniel King",
        reason: "Preventive care",
        specialist: "Dr. Elizabeth Harris",
        dateOfReferral: "2024-09-05",
        notes: "Preventive check-up and health screening."
    },
    {
        id: 7,
        patientName: "Grace Taylor",
        dob: "1988-04-20",
        contact: "555-7890",
        referringDoctor: "Dr. Amanda White",
        reason: "Nutritional counseling",
        specialist: "Dr. Michael Johnson",
        dateOfReferral: "2024-09-04",
        notes: "Counseling for weight management."
    },
    {
        id: 8,
        patientName: "Hannah Harris",
        dob: "1995-02-14",
        contact: "555-0987",
        referringDoctor: "Dr. Christopher Hall",
        reason: "Orthopedic evaluation",
        specialist: "Dr. Laura Martinez",
        dateOfReferral: "2024-09-03",
        notes: "Evaluation for recent injury."
    }
];

export default mockData;
