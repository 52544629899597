import React from "react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import DoctorsList from "../sections/DocActiveList/docActiveList"
import HospitalsList from "../sections/HospitalActiveList/hospitalActiveList";

const FaqPage = () => {
  return (
    <div>
      <Header />
      <DoctorsList />
      <HospitalsList />
      <Footer />
    </div>
  );
};

export default FaqPage;
