import React, { useState } from "react";
import Header from "../HomePage/sections/Header";
import Footer from "../HomePage/sections/Footer";
import Filters from "../HomePage/sections/Filters/filters";
import Team from "../HomePage/sections/Team";

const Find = () => {
  const [filterData] = useState({
    area: ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix"],
    price: [],
    specialty: [
      "Cardiology",
      "Neurology",
      "Orthopedics",
      "Pediatrics",
      "Dermatology",
    ],
    disease: ["COVID-19", "Diabetes", "Hypertension", "Asthma", "Cancer"],
    insurance: ["Yes", "No"],
    governmentJob: ["Yes", "No"],
  });

  return (
    <div>
      <Header />
      <div>
        <Filters filterData={filterData} />
      </div>
      <Team />
      <Footer />
    </div>
  );
};

export default Find;
