import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { tokens } from "../../../../theme";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import AirportShuttleSharpIcon from "@mui/icons-material/AirportShuttleSharp";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import Header from "../../Common/Heading/Heading";
import StatBox from "./StatBox";
import AmbulanceList from "./AmbulanceList";
import "./index.css"; // Importing the CSS file

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
  });

  // Open the modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the modal
  const handleClose = () => {
    setOpen(false);
  };

  // Auto-detect location using Geolocation API
  useEffect(() => {
    if (open && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setFormData((prev) => ({
            ...prev,
            location: `Lat: ${latitude}, Long: ${longitude}`,
          }));
        },
        (error) => {
          setFormData((prev) => ({
            ...prev,
            location: "Location not available",
          }));
        }
      );
    }
  }, [open]);

  // Handle input change
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log("Form submitted:", formData);
    handleClose();
  };

  return (
    <Box className="dashboard-container">
      <Box className="dashboard-header">
        <Header
          title="AMBULANCE DASHBOARD"
          subtitle="Welcome to your dashboard"
        />
        <Box>
          <Button
            onClick={handleClickOpen}
            className="emergency-booking-button"
          >
            Emergency Booking
          </Button>
        </Box>
      </Box>

      {/* DIALOG FOR EMERGENCY BOOKING */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Emergency Booking</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            color="secondary"
            fullWidth
            variant="standard"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phone"
            color="secondary"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="standard"
            value={formData.phone}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="location"
            color="secondary"
            label="Location"
            type="text"
            fullWidth
            variant="standard"
            value={formData.location}
            onChange={handleInputChange}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* GRID & CHARTS */}
      <Box className="dashboard-grid">
        {/* ROW 1 */}
        <Box className="dashboard-grid-item">
          <StatBox
            title="12"
            subtitle="Ambulances Live"
            progress="0.4"
            increase="+40%"
            icon={
              <AirportShuttleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
          <Box className="live-indicator">
            <Box className="blinking-dot" />
            <Typography className="live-text">LIVE</Typography>
          </Box>
        </Box>

        <Box className="dashboard-grid-item">
          <StatBox
            title="18"
            subtitle="Ambulances Available"
            progress="0.60"
            increase="+60%"
            icon={
              <AirportShuttleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box className="dashboard-grid-item">
          <StatBox
            title="30"
            subtitle="Total Number of Ambulances"
            progress="1.00"
            increase="+100%"
            icon={
              <AirportShuttleSharpIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box className="dashboard-grid-item">
          <StatBox
            title="18"
            subtitle="Drivers Available"
            progress="0.6"
            increase="+60%"
            icon={
              <AirlineSeatReclineNormalIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box className="dashboard-services">
          <Box className="services-header">
            <Header title="AMBULANCE SERVICES" titleColor="rgb(76,206,172)" />
          </Box>
          <Box mb="0px">
            <AmbulanceList />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box className="dashboard-info-box">
          <Typography variant="h3" fontWeight="700">
            Pink Ambulance
          </Typography>
          <Box
            component="img"
            src="/pink-ambu-1.png"
            alt="Pink Ambulance"
            className="ambulance-image"
          />
          <Typography className="info-text">
            Women driver for emergency women patient
          </Typography>
        </Box>

        <Box className="dashboard-info-box">
          <Typography variant="h3" fontWeight="700">
            Bulk Ambulance
          </Typography>
          <Box
            component="img"
            src="/bulk-ambu.png"
            alt="Bulk Ambulance"
            className="ambulance-image"
          />
          <Typography className="info-text">
            For Emergency Multiple Ambulance Needs
          </Typography>
        </Box>

        <Box className="dashboard-info-box">
          <Typography variant="h3" fontWeight="700">
            Rent Ambulance
          </Typography>
          <Box
            component="img"
            src="/rent-ambu.png"
            alt="Rent Ambulance"
            className="ambulance-image"
          />
          <Typography className="info-text">
            For Events, travel, transfer & more
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
