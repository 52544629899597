// utils/configLoader.js
export const loadConfig = async (dashboardType) => {
  try {
    // Dynamically import the configuration based on the dashboard type
    switch (dashboardType) {
      case "Admin":
        return await import("../../Admin/Admin");
      case "Doctor":
        return await import("../../Doctor/Doctor");
      case "Diagnostics":
        return await import("../../Diagnostics/Diagnostics");
      case "Home Care":
        return await import("../../HomeCare/HomeCare");
      case "Hospital":
        return await import("../../Hospital/Hospital");
      case "Laboratory":
        return await import("../../Laboratory/Laboratory");
      case "NGO":
        return await import("../../NGO/NGO");
      case "Patient":
        return await import("../../Patient/Patient");
      case "Pharmacy":
        return await import("../../Pharmacy/Pharmacy");
      case "Ambulance":
        return await import("../../Ambulance/Ambulance");
      default:
        throw new Error("Unknown dashboard type");
    }
  } catch (error) {
    console.error("Failed to load configuration:", error);
    return { tabs: [] };
  }
};
