import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import styles from "./StaffList.module.css";
import StaffListCard from "./StaffListCard";
import api from "../../../../api";
import { useAuth } from "../../../../AuthContext";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { tabs } from "../Admin";

export default function StaffList() {
  const { setLoader, setAlert, setAlertMsg } = useAuth();
  const [staffs, setStaffs] = useState([]);
  const [theme, colorMode] = useMode();

  useEffect(() => {
    async function fetchStaffs() {
      try {
        setLoader(true);
        const res = await api.staffList();
        if (res.data.error) {
          setLoader(false);
          setAlertMsg(res.data.errorMsg);
          setAlert(true);
        } else {
          setLoader(false);
          setStaffs(res.data);
        }
      } catch (error) {
        setLoader(false);
        setAlertMsg(error?.response?.data?.errorMsg || "An Error Occured!");
        setAlert(true);
        console.error(error);
      }
    }

    fetchStaffs();
  }, [setLoader, setAlert, setAlertMsg]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.container}>
          {/* <Navbar /> */}
          <Sidebar tabs={tabs} />
          <div className={styles.mainContent}>
            <Topbar />
          </div>
          <div className={styles.cardContainer}>
            <Grid container spacing={3}>
              {staffs.map((staff, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <StaffListCard staff={staff} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
