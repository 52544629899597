import React from "react";
import styles from "./Consultations.module.css"; // Import CSS module
import { Box, Typography, Grid, Card, CardMedia, CardContent } from "@mui/material";

const consultationsData = [
  {
    id: 1,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Unlimited Free consultations",
    description: "Get unlimited free consultations with all doctors 24x7",
  },
  {
    id: 2,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Unlimited Free consultations",
    description: "Get unlimited free consultations with all doctors 24x7",
  },
  {
    id: 3,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Free For All Family Members",
    description: "Free consultations for up to 6 family members",
  },
  {
    id: 4,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Free For All Health Problems",
    description: "Consult with any doctor from 18 departments at any time",
  },
];

const Consultations = () => {
  return (
    <Box className={styles.consultationContainer}>
      <Typography variant="h2" className={styles.heading}>
        Give The Care Your Family Deserves
      </Typography>
      <Typography variant="h4" className={styles.subheading}>
        Unlimited Consultations with top Doctors for 6 family members in 22+
        specialities and 16 languages
      </Typography>
      <grid container className={styles.cardGrid}>
        {consultationsData.map((consultation) => (
          <Grid item key={consultation.id}>
            <Card className={styles.card}>
              <CardMedia
                component="img"
                height="140"
                image={consultation.image}
                alt={consultation.title}
                className={styles.cardImage}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  className={styles.cardTitle}
                >
                  {consultation.title}
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.cardDescription}
                >
                  {consultation.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </grid>
    </Box>
  );
};

export default Consultations;
