import React from "react";
import "./callToAction.css";
const callToAction = () => {
  return (
    <div className="">
      <section className="calltoaction">
        <div className="background-container">
          <span className="background-overlay"></span>
          <div className="background-cover container">
            <div className="line-heading">
              <div className="line-heading-left">
                <h2>Consultation</h2>
                <h4>with top doctors 24/7</h4>
              </div>

              <p>
              At Aspatt Healthcare, we understand that your time is valuable, which is why we’re bringing expert healthcare consultations right to your fingertips. Say goodbye to long queues and waiting rooms—our team of experienced professionals is now available for convenient video consultations. Whether you're seeking medical advice or follow-up care, you can now connect with trusted specialists from the comfort of your home.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default callToAction;
