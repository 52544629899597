import {
  Box,
  IconButton,
  useTheme,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext } from "../../../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import styles from "./Topbar.module.css";
import jwt_decode from "jwt-decode";
import api from "../../../../api"; // Adjust the path according to your project
import { useAuth } from "../../../../AuthContext"; // Adjust the path according to your project
import { useNavigate } from "react-router-dom";

import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";

import { FaCircleUser } from "react-icons/fa6";
import { FaSignOutAlt } from "react-icons/fa";
const Topbar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Fetch token and user data
  const token = localStorage.getItem("accessToken");
  const userName = token ? jwt_decode(token)?.name : null;
  const { setUserType, setLoader, setAlert, setAlertMsg } = useAuth();

  // Handle menu actions
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const dashboardType = searchParams.get("dashboard");

  // const getUserRole = () => {
  //   try {
  //     const token = localStorage.getItem("accessToken");
  //     if (token) {
  //       const decodedToken = jwt_decode(token);
  //       // Assuming the role is stored in the token's payload under 'role' key
  //       return decodedToken.role || "default"; // Default role if not specified
  //     }
  //     return "guest"; // Return a default role if no token is present
  //   } catch (error) {
  //     console.error("Failed to decode token:", error);
  //     return "guest"; // Return a default role in case of error
  //   }
  // };

  // const getUserRole = () => {

  //   // const { user } = useContext(useAuth); // Destructure user from context
  //   // return user?.userType || "guest"; // Return user role or default to "guest"
  // };
  const userType = token ? jwt_decode(token)?.userType : null;

  const handleProfileClick = () => {
    const dashboardType = userType;
    navigate(`/dashboard/profile?dashboard=${dashboardType}`); // Redirect to the profile page
    handleMenuClose();
  };
  const handleNavigateHome = () => {
    navigate("/");
  };


  const handleLogOut = async () => {
    try {
      setLoader(true);
      const refreshToken = localStorage.getItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      await api.logout({ data: { refreshToken } });
      setLoader(false);
      setUserType(null);
      navigate("/signin");
    } catch (error) {
      setLoader(false);
      setAlertMsg(error?.response?.data?.error || "An Error Occurred!");
      setAlert(true);
      console.error(error);
    }
  };

  return (
    <Box className={styles.topbarContainer}>
      {/* SEARCH BAR */}
      <div className={styles.searchBar}>
        <button onClick={handleNavigateHome}>Go to Website</button>
      </div>

      {/* ICONS */}
      <Box display="flex">
        <IconButton
          onClick={colorMode.toggleColorMode}
          className={styles.iconButton}
        >
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon className={styles.icon} />
          ) : (
            <LightModeOutlinedIcon className={styles.icon} />
          )}
        </IconButton>
        <IconButton className={styles.iconButton}>
          <NotificationsOutlinedIcon className={styles.icon} />
        </IconButton>
        <IconButton className={styles.iconButton}>
          <SettingsOutlinedIcon className={styles.icon} />
        </IconButton>

        {/* PROFILE DROPDOWN */}
        <IconButton onClick={handleMenuClick} className={styles.iconButton}>
          <PersonOutlinedIcon className={styles.icon} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem disabled>Hello, {userName || "User"}</MenuItem>
          <Divider />
          <MenuItem onClick={handleProfileClick}>
            <ListItemIcon>
              <FaCircleUser />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem className={styles.menuItem} onClick={handleLogOut}>
            <ListItemIcon>
              <FaSignOutAlt />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
