import React, { useEffect } from "react";
import styles from "./serviceSection.module.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

const ServiceSection = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);
  return (
    <div className={styles.container}>
      <section className={styles.secondSection}>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>Best Diagnostic Service</h2>
          <p className={styles.p}>
            we understand that correct diagnosis for a disease or health issue is crucial in treatment and the results of diagnostic tests are the cornerstone for an effective treatment plan. To this end, we offer an extensive range of services
          </p>
          <button>Book Now</button>
        </div>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/service1.png" alt="Our Mission" />
        </div>
      </section>

      {/* Third Section: What Sets Us Apart */}
      <section className={styles.thirdSection}>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/service2.png" alt="What Sets Us Apart" />
          <div className={styles.img5}>
            <img src="/service3.png" alt="Our Mission" />
          </div>
        </div>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>Best Laboratory Service</h2>
          <p className={styles.p}>
          Physicians with special training in the diagnosis and detection of disease. individuals assist with the gross description and dissection of surgical cases and biopsies, working closely with supervising pathologists.
          </p>
          <button>Book Now</button>
        </div>
      </section>
      {/* fourth Section: Our Mission */}
      <section className={styles.secondSection}>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>Best Homecare Service</h2>
          <p className={styles.p}>
          Physicians with special training in the diagnosis and detection of disease. individuals assist with the gross description and dissection of surgical cases and biopsies, working closely with supervising pathologists.
          </p>
          <button>Book Now</button>
        </div>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/service4.png" alt="Our Mission" />
          <div className={styles.img5}>
            <img src="/service5.png" alt="Our Mission" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceSection;
