// Calendar.js
import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import styles from "./DocAppointments.module.css";
import { tabs } from "../Doctor";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const timeDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "45 minutes", value: 45 },
  { label: "1 hour", value: 60 },
];

const Calendar = () => {
  const [theme, colorMode] = useMode();

  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    date: "",
    startTime: "",
    endTime: "",
    duration: "",
    shortDescription: "",
    description: "",
  });
  const [error, setError] = useState("");

  const now = new Date();
  const currentDate = now.toISOString().split("T")[0];
  const currentTime = now.toTimeString().split(" ")[0].substring(0, 5);

  const handleNewMeetingClick = () => {
    setShowForm(true);
  };

  const handleDateClick = (info) => {
    setSelectedDate(info.dateStr);
    setFormData({ ...formData, date: info.dateStr });
  };

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const selectedDateTime = new Date(`${formData.date}T${formData.startTime}`);

    if (selectedDateTime < now) {
      setError("The meeting date and time must be in the future.");
      return;
    }

    const newEvent = {
      title: formData.shortDescription,
      description: formData.description,
      start: `${formData.date}T${formData.startTime}:00`,
      end: `${formData.date}T${formData.endTime}:00`,
      extendedProps: {
        name: formData.name,
        age: formData.age,
        date: formData.date,
        startTime: formData.startTime,
        endTime: formData.endTime,
        shortDescription: formData.shortDescription,
        description: formData.description,
      },
    };
    setEvents([...events, newEvent]);
    setShowForm(false);
    setFormData({
      name: "",
      age: "",
      date: "",
      startTime: "",
      endTime: "",
      duration: "",
      shortDescription: "",
      description: "",
    });
    setError("");
  };

  const handleFormCancel = () => {
    setShowForm(false);
    setFormData({
      name: "",
      age: "",
      date: "",
      startTime: "",
      endTime: "",
      duration: "",
      shortDescription: "",
      description: "",
    });
    setError("");
  };

  const handlePopupClose = () => {
    setSelectedEvent(null);
  };

  const handleDurationChange = (e) => {
    const duration = e.target.value;
    const [startHour, startMinute] = formData.startTime.split(":");
    const startDateTime = new Date();
    startDateTime.setHours(parseInt(startHour), parseInt(startMinute), 0);
    const endDateTime = new Date(startDateTime.getTime() + duration * 60000);
    const endHour = endDateTime.getHours().toString().padStart(2, "0");
    const endMinute = endDateTime.getMinutes().toString().padStart(2, "0");

    setFormData({
      ...formData,
      duration,
      endTime: `${endHour}:${endMinute}`,
    });
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.container}>
          {/* <Navbar /> */}
          <Sidebar tabs={tabs} />
          <div className={styles.mainContent}>
            <Topbar />
          </div>
          <div className={styles.calendarContainer}>
            <button
              className={styles.newMeetingButton}
              onClick={handleNewMeetingClick}
            >
              + New Appoitment
            </button>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
              initialView={selectedDate ? "timeGridDay" : "dayGridMonth"}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              events={events.map((event) => ({
                title: `${event.title} - ${event.extendedProps.shortDescription}`,
                start: event.start,
                end: event.end,
                extendedProps: event.extendedProps,
              }))}
              dateClick={handleDateClick}
              eventClick={handleEventClick}
              slotMinTime="00:00:00"
              slotMaxTime="24:00:00"
              editable={true}
              droppable={true}
              aspectRatio={1.5}
              fixedWeekCount={false}
            />
            {showForm && (
              <div className={styles.formOverlay}>
                <form
                  className={styles.meetingForm}
                  onSubmit={handleFormSubmit}
                >
                  <h2>New Meeting</h2>
                  {error && <p className={styles.error}>{error}</p>}
                  <div className={styles.formContent}>
                    <label>
                      Name:
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                      />
                    </label>
                    <label>
                      Age:
                      <input
                        type="number"
                        name="age"
                        value={formData.age}
                        onChange={(e) =>
                          setFormData({ ...formData, age: e.target.value })
                        }
                      />
                    </label>
                    <label>
                      Date:
                      <input
                        type="date"
                        name="date"
                        value={formData.date}
                        min={currentDate}
                        onChange={(e) =>
                          setFormData({ ...formData, date: e.target.value })
                        }
                        required
                      />
                    </label>
                    <label>
                      Start Time:
                      <input
                        type="time"
                        name="startTime"
                        value={formData.startTime}
                        min={
                          currentDate === formData.date ? currentTime : "00:00"
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            startTime: e.target.value,
                          })
                        }
                        required
                      />
                    </label>
                    <label>
                      Duration:
                      <select
                        name="duration"
                        value={formData.duration}
                        onChange={handleDurationChange}
                      >
                        <option value="">Select Duration</option>
                        {timeDurations.map((duration) => (
                          <option key={duration.value} value={duration.value}>
                            {duration.label}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label>
                      End Time:
                      <input
                        type="time"
                        name="endTime"
                        value={formData.endTime}
                        readOnly
                      />
                    </label>
                    <label>
                      Short Description:
                      <input
                        type="text"
                        name="shortDescription"
                        value={formData.shortDescription}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            shortDescription: e.target.value,
                          })
                        }
                        required
                      />
                    </label>
                    <label>
                      Description:
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                    </label>
                  </div>
                  <div className={styles.formButtons}>
                    <button type="submit" className={styles.saveButton}>
                      Save
                    </button>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={handleFormCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
            {selectedEvent && (
              <div className={styles.popupOverlay}>
                <div className={styles.popupContent}>
                  <h2>{selectedEvent.extendedProps.shortDescription}</h2>
                  <p>
                    <strong>Name:</strong> {selectedEvent.extendedProps.name}
                  </p>
                  <p>
                    <strong>Age:</strong> {selectedEvent.extendedProps.age}
                  </p>
                  <p>
                    <strong>Date:</strong> {selectedEvent.extendedProps.date}
                  </p>
                  <p>
                    <strong>Start Time:</strong>{" "}
                    {selectedEvent.extendedProps.startTime}
                  </p>
                  <p>
                    <strong>End Time:</strong>{" "}
                    {selectedEvent.extendedProps.endTime}
                  </p>
                  <p>
                    <strong>Description:</strong>{" "}
                    {selectedEvent.extendedProps.description}
                  </p>
                  <button
                    className={styles.closeButton}
                    onClick={handlePopupClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Calendar;
