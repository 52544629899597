import React from "react";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import FlipIcon from "@mui/icons-material/Flip";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import Card from "./Card"; // Adjust the import path as necessary

import Topbar from "../../Common/Topbar/Topbar";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { tabs } from "../Hospital"
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider, Box, Typography } from "@mui/material";

const Services = () => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          {/* Sidebar */}
          <Sidebar tabs={tabs} sx={{ width: "250px", height: "910px" }} />

          {/* Main Content */}
          <Box
            sx={{
              flexGrow: 1,
              marginLeft: "270px",
            }}
          >
            <Topbar />
            <Box
              sx={{
                // marginTop: "95px",
                marginLeft: "50px",
                marginRight: "50px",
              }}
            ></Box>
            <Typography
              variant="h4"
              align="center"
              sx={{ mb: 10, mt: 5, fontSize: "40px", fontWeight: "900" }}
            >
              SERVICES
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                gap: "60px",
                marginLeft: "200px",
                marginTop: "125px",
              }}
            >
              <Card
                icon={<NotificationImportantIcon sx={{ fontSize: "60px" }} />}
                header="Riage and Stabilization"
                description="Initial assessment and stabilization of critical patients."
                linkTo="/dashboard/Hospital/Doctors"
              />
              <Card
                icon={<MonitorHeartIcon sx={{ fontSize: "60px" }} />}
                header="Resuscitation"
                description="Emergency response for life-threatening conditions."
                linkTo="/dashboard/Hospital/Doctors"
              />
              <Card
                icon={<FlipIcon sx={{ fontSize: "60px" }} />}
                header="Wound Care"
                description="Treatment of cuts, fractures, burns, and other injuries."
                linkTo="/dashboard/Hospital/Doctors"
              />
              <Card
                icon={<VaccinesIcon sx={{ fontSize: "60px" }} />}
                header="Acute Illness Management"
                description="Rapid diagnosis and treatment of sudden illnesses."
                linkTo="/dashboard/Hospital/Doctors"
              />
              {/* Add more cards as needed */}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Services;
