// mockdatafeedback.js
export const mockDataFeedbacks = [
    {
      id: 1,
      patientName: "John Doe",
      rating: 4.5,
      comments: "Great service, very satisfied!",
      feedbackDate: "2024-09-01",
      appointmentDate: "2024-08-30",
      serviceType: "Consultation",
      doctorResponse: "Thank you for your feedback!",
      feedbackStatus: "Reviewed",
      patientAgeGroup: "Adult",
      severity: "Positive",
      followUpRequired: false,
    },
    {
      id: 2,
      patientName: "Jane Smith",
      rating: 3.0,
      comments: "Good experience, but room for improvement.",
      feedbackDate: "2024-09-02",
      appointmentDate: "2024-08-29",
      serviceType: "Surgery",
      doctorResponse: "",
      feedbackStatus: "Pending",
      patientAgeGroup: "Senior",
      severity: "Neutral",
      followUpRequired: true,
    },
    {
      id: 3,
      patientName: "Michael Johnson",
      rating: 5.0,
      comments: "Exceptional care and attention to detail.",
      feedbackDate: "2024-09-03",
      appointmentDate: "2024-08-28",
      serviceType: "Consultation",
      doctorResponse: "Glad to hear that!",
      feedbackStatus: "Reviewed",
      patientAgeGroup: "Adult",
      severity: "Positive",
      followUpRequired: false,
    },
    {
      id: 4,
      patientName: "Emily Davis",
      rating: 2.5,
      comments: "Average service, not very attentive.",
      feedbackDate: "2024-09-04",
      appointmentDate: "2024-08-27",
      serviceType: "Physical Therapy",
      doctorResponse: "",
      feedbackStatus: "Pending",
      patientAgeGroup: "Child",
      severity: "Neutral",
      followUpRequired: true,
    },
    {
      id: 5,
      patientName: "David Wilson",
      rating: 4.0,
      comments: "Very good, but could improve on wait times.",
      feedbackDate: "2024-09-05",
      appointmentDate: "2024-08-26",
      serviceType: "Consultation",
      doctorResponse: "We are working on reducing wait times.",
      feedbackStatus: "Action Taken",
      patientAgeGroup: "Senior",
      severity: "Positive",
      followUpRequired: false,
    },
    {
      id: 6,
      patientName: "Sophia Brown",
      rating: 3.5,
      comments: "Friendly staff but the appointment was delayed.",
      feedbackDate: "2024-09-06",
      appointmentDate: "2024-08-25",
      serviceType: "Dental Checkup",
      doctorResponse: "We apologize for the delay.",
      feedbackStatus: "Reviewed",
      patientAgeGroup: "Adult",
      severity: "Neutral",
      followUpRequired: false,
    },
    {
      id: 7,
      patientName: "James Taylor",
      rating: 1.5,
      comments: "Not satisfied with the consultation.",
      feedbackDate: "2024-09-07",
      appointmentDate: "2024-08-24",
      serviceType: "Consultation",
      doctorResponse: "",
      feedbackStatus: "Pending",
      patientAgeGroup: "Adult",
      severity: "Negative",
      followUpRequired: true,
    },
    {
      id: 8,
      patientName: "Olivia Miller",
      rating: 4.0,
      comments: "Good experience overall.",
      feedbackDate: "2024-09-08",
      appointmentDate: "2024-08-23",
      serviceType: "Surgery",
      doctorResponse: "Thank you for your feedback.",
      feedbackStatus: "Reviewed",
      patientAgeGroup: "Senior",
      severity: "Positive",
      followUpRequired: false,
    },
    {
      id: 9,
      patientName: "Lucas Martinez",
      rating: 5.0,
      comments: "Top-notch service!",
      feedbackDate: "2024-09-09",
      appointmentDate: "2024-08-22",
      serviceType: "Consultation",
      doctorResponse: "We are glad you had a great experience!",
      feedbackStatus: "Reviewed",
      patientAgeGroup: "Adult",
      severity: "Positive",
      followUpRequired: false,
    },
  ];
  