import React, { useEffect, useState } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "./Testimonial.css";

const Testimonial = () => {
  const [slidesPerView, setSlidesPerView] = useState(3); // Default to 3 slides per view

  useEffect(() => {
    const testimonialSwiper = new Swiper(".swiper", {
      loop: true,
      speed: 1000,
      autoplay: false,
      slidesPerView: slidesPerView,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    //Clean up Swiper instance on component unmount
    return () => {
      testimonialSwiper.destroy();
    };
  }, [slidesPerView]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1);
      } else if (width >= 768 && width < 992) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container">
      <section id="testimonials" className="section">
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Our Happy Clients</h2>
                <img src="img/section-img.png" alt="#" />
                <p>
                  We provide the best healthcare Treatment Service with best
                  quality materials on the market in order to provide the best
                  products to our patients.
                </p>
              </div>
            </div>
          </div>

          <div className=" testimonials-contain row justify-content-center">
            <div className="col" data-aos="fade-up" data-aos-delay="200">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <div className="d-flex">
                        <img
                          src="team/aarav-kapoor.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>Aarav Kapoor</h3>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        <span>
                          "Aspatt Healthcare provided exceptional service during
                          my video consultation. The expert guidance I received
                          saved me multiple hospital visits and long queues.
                          Highly recommend their professional team for any
                          healthcare needs!"
                        </span>
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <div className="d-flex">
                        <img
                          src="team/kavya-sharma.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>Kavya Sharma</h3>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        <span>
                          "I was able to get a detailed consultation from a
                          specialist without leaving my home. Aspatt Healthcare
                          made the whole process smooth and convenient. No more
                          waiting rooms for me!"
                        </span>
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <div className="d-flex">
                        <img
                          src="team/rohan-mehta.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>Rohan Mehta</h3>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        <span>
                          "The ease of booking a video consultation through
                          Aspatt Healthcare was amazing. The doctors were
                          knowledgeable, and I got the same quality care as an
                          in-person visit. I’ll definitely be using their
                          services again."
                        </span>
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <div className="d-flex">
                        <img
                          src="team/team-4.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>Neha Patel</h3>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        <span>
                          "Aspatt Healthcare’s video consultations saved me so
                          much time. The specialists were attentive, and I got
                          my treatment plan without the hassle of traveling to a
                          hospital. Great service!"
                        </span>
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
