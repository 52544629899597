import React, { useEffect } from "react";
import "./ServicesThree.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

const ServicesThree = (props) => {
  const {
    heading,
    subHeading,
    buttonText1,
    buttonText2,
    buttonUrl1,
    buttonUrl2,
    data,
  } = props;

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);

  return (
    <div>
      <section className="ServiceThree">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>{heading}</h2>
                <h3>{subHeading}</h3> {/* Changed to h3 for better semantics */}
                <img src="img/section-img.png" alt="Section" />
              </div>
            </div>
          </div>
          <div className="card-container">
            {data &&
              data.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="icon-box text-center"
                  data-aos="zoom-in"
                >
                  <div className="icon-circle">
                    {item.src ? (
                      <img
                        src={item.src}
                        alt={item.heading}
                        className="custom-icon"
                      />
                    ) : (
                      <i className={`${item.icon} fa-2x colored-icon`}></i>
                    )}
                  </div>
                  <p>{item.heading}</p>
                </a>
              ))}
          </div>

          <div className="button-container">
            <a
              href={buttonUrl1}
              className="View btn-sec"
              data-aos="zoom-in" // Adding AOS zoom-in effect to the View button
            >
              {buttonText1}
            </a>
            <a
              href={buttonUrl2}
              className="Enquiry btn"
              data-aos="zoom-in" // Adding AOS zoom-in effect to the Enquiry button
            >
              {buttonText2}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesThree;
