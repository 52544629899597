import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card as MuiCard } from "@mui/material";

const Card = ({ icon, header, description, linkTo }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(linkTo);
  };

  return (
    <MuiCard
      sx={{
        width: 240,
        height: 272,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
      onClick={handleClick}
    >
      <Box sx={{ mb: 2 }}>{icon}</Box>
      <Typography variant="h6" align="center" gutterBottom>
        {header}
      </Typography>
      <Typography variant="body2" align="center">
        {description}
      </Typography>
    </MuiCard>
  );
};

export default Card; // Ensure this is a default export
