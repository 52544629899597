import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import api from "../../api";
import { useAuth } from "../../AuthContext";
import AOS from "aos";

const options = [
  "Select User Type",
  "Diagnostics",
  "Pharmacy",
  "Hospital",
  "Doctor",
  "NGO",
  "Home Care",
  "Laboratory",
  "Ambulance",
  "Patient",
];


export default function SignUp() {
  const { setLoader, setAlert, setAlertMsg, setAlertType } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(options[0]);
  const [department, setDepartment] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [clinicName,setClinicName] = useState("");
  const [fname, setFname] = useState("");
  const [pmcNo, setPmcNo] = useState("")
  // const [lname, setLname] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [currentlocation, setCurrentLocation] = useState("");
  const [address, setAddredss] = useState("");
  const [insurance, setInsurance] = useState("");
  const [medicalcard, setMedicalCard] = useState("");
  const [governmentjob, setGovernmentJob] = useState("");
  const [password, setPassword] = useState("");

  const handleFileUpload = (event) => {
    const files = event.target.files;
    
    if (files.length > 10) {
      alert("You can upload a maximum of 10 files.");
      return;
    }
  
    // Process files here (e.g., set them in state, upload to server, etc.)
    console.log(files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (user === "Select User Type") {
      setAlertMsg("Please select a user type.");
      setAlertType("error");
      setAlert(true);
      return;
    }
  
    const formData = new FormData();
    formData.append("userType", user);
    formData.append("fname", fname);
    formData.append("pmcNo", pmcNo);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("dateofbirth", dateofbirth);
    formData.append("currentlocation", currentlocation);
    formData.append("address", address);
    formData.append("insurance", insurance);
    formData.append("governmentjob", governmentjob);
    formData.append("medicalcard", medicalcard);
    formData.append("password", password);
    formData.append("clinicName",clinicName);
    formData.append("department", department);
    formData.append("speciality", speciality);
  
    // Append files if any are uploaded
    const files = document.querySelector('#documents').files;
    for (let i = 0; i < files.length; i++) {
      formData.append("documents", files[i]);
    }
  
    try {
      setLoader(true);
      const res = await api.signup(formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      if (res.data.error) {
        setLoader(false);
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        // Reset form fields after success
        setLoader(false);
        setUser(options[0]);
        // ...reset other fields as needed...
        navigate("/signin");
      }
    } catch (error) {
      setLoader(false);
      setAlertMsg(error.response?.data?.errorMsg || "An error occurred!");
      setAlert(true);
      console.error(error);
    }
  };
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          {/* Left Column */}
          <div className={styles.col} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src="/signup.png" alt="doctor" className={styles.image} draggable="false" />
          </div>
          {/* Right Column - Form */}
          <div className={styles.col}>
            <div className={styles.maincontainer}>
              <Typography component="h1" variant="h3" className={styles.header} data-aos="zoom-in">
                Create An Account
              </Typography>
              <Typography variant="h6" data-aos="zoom-in">
                Fill up the details to create account
              </Typography>

              {/* User Type Field */}
              <div className={styles.userTypeContainer}>
                <select
                  className={styles.selectInput}
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <form className={styles.form} onSubmit={handleSubmit}data-aos="fade-left">

                {/* Name Fields */}
                <div className={styles.formGroup} >
                  <input
                    type="text"
                    name="firstName"
                    required
                    id="firstName"
                    value={fname}
                    placeholder="*Type your Name"
                    onChange={(event) => setFname(event.target.value)}
                  />
                </div>
                <div className={styles.formRow}>
                <div className={styles.formGroup} >
                  <input
                    type="number"
                    name="number"
                    required
                    id="number"
                    value={mobileNumber}
                    placeholder="Mobile Number"
                    onChange={(event) => setMobileNumber(event.target.value)}
                  />
                </div>
                

                {/* Email Field */}
                <div className={styles.formGroup} >
                  <input
                    required
                    id="email"
                    type="email"
                    value={email}
                    autoComplete="email"
                    placeholder="*Enter your email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                </div>
                <div className={styles.formRow}>
                  {user === "Doctor" && (
                      <div className={styles.formGroup}>
                          <input
                            type="text"
                            name="department"
                            required
                            id="department"
                            placeholder="*Enter the Department Name"
                            value={department}
                            onChange={(event) => setDepartment(event.target.value)}
                          />
                      </div>
                      )}
                      {user === "Doctor" && (
                        <div className={styles.formGroup}>
                          <input
                            type="text"
                            name="speciality"
                            required
                            id="speciality"
                            placeholder="*Enter the Speciality Name "
                            value={speciality}
                            onChange={(event) => setSpeciality(event.target.value)}
                          />
                      </div>
                      )}
                    </div>
                    {user === "Doctor" && (
                        <div className={styles.formGroup}>
                          <input
                            type="text"
                            name="clinicName"
                            required
                            id="clinicName"
                            placeholder="*Enter the Clinic Name "
                            value={clinicName}
                            onChange={(event) => setClinicName(event.target.value)}
                          />
                      </div>
                      )}
                {/* Date of Birth Field */}
                {/* <div className={styles.formGroup}>
                  <input
                    type="date"
                    name="Date of Birth"
                    id="Date of Birth"
                    value={dateofbirth}
                    placeholder="Date of Birth"
                    onChange={(event) => setDateOfBirth(event.target.value)}
                  />
                </div> */}
                <div className={styles.formRow}>
                  {/* Location Field */}
                  {/* <div className={styles.formGroup}>
                    <input
                      type="text"
                      name="CurrentLocation"
                      id="Current Location"
                      value={currentlocation}
                      placeholder="Current Location"
                      onChange={(event) => setCurrentLocation(event.target.value)}
                    />
                  </div> */}
                  {/* Address Field */}
                  <div className={styles.formGroup} >
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={address}
                      placeholder="+ Add Address"
                      onChange={(event) => setAddredss(event.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.formRow}>
                {/* Insurance Field */}
                <div className={styles.formGroup}>
                  <input
                    name="insurance"
                    type="insurance"
                    id="insurance"
                    value={insurance}
                    placeholder="Insurance If Any"
                    onChange={(event) => setInsurance(event.target.value)}
                  />
                </div>
                {/* Pmc No Field */}
                <div className={styles.formGroup}>
                  <input
                    name="pmcNo"
                    type="pmcNo"
                    id="pmcNo"
                    value={pmcNo}
                    placeholder="*PMC No. / Registration No. "
                    onChange={(event) => setPmcNo(event.target.value)}
                  />
                </div>
                </div>
                <div className={styles.formRow}>
                  {/* Government Job Field */}
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      name="governmentJob"
                      id="Goverment Job"
                      value={governmentjob}
                      placeholder="Government Job"
                      onChange={(event) => setGovernmentJob(event.target.value)}
                    />
                  </div>
                  {/* Medical Card Field */}
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      name="medicalcard"
                      id="medicalcard"
                      value={medicalcard}
                      placeholder="Medical Card"
                      onChange={(event) => setMedicalCard(event.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.formGroup}>
                    <input
                      type="file"
                      name="documents"
                      id="documents"
                      accept=".pdf,.jpg,.jpeg"
                      multiple
                      placeholder="Upload Documnets"
                      onChange={(event) => handleFileUpload(event)}
                    />
                  </div>

                {/* Password Field */}
                <div className={styles.formGroup}>
                  <input
                    required
                    name="password"
                    type="password"
                    id="password"
                    value={password}
                    placeholder="*Create a Password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div>
                  <input type="checkbox" required />
                  <span>I agree with Terms and Privacy (mandatory)</span>
                </div>
                {/* Submit Button */}
                <button type="submit" className={styles.submitButton} >
                  Sign Up
                </button>

                <Link to="/signin" className={styles.linkBtn}>
                  {"Already have an account? Sign In"}
                </Link>
              </form>
            </div>
          </div>

          {/* Right Column - Image */}
          {/* <div className={styles.col}>
            <img
              src={userImages[user.replace(/ /g, "")] || "/medicine.svg"}
              alt={user}
              className={styles.userTypeImage}
            />
          </div> */}
          
        </div>
      </div>
    </section>
  );
}
