import React from "react";
import styles from "../Common/Dashboard.module.css";
import Sidebar from "../Common/Sidebar/Sidebar";
import Topbar from "../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export const tabs = [
  { title: "Home", redirect: "/dashboard/Doctor/" },
  { title: "Appointments", redirect: "/dashboard/doctor/appointments" },
  // { title: "Upload Prescription", redirect: "/dashboard/doctor/upload-prescription" },
  { title: "Patient List", redirect: "/dashboard/doctor/patient-list" },
  { title: "Services", redirect: "/dashboard/doctor/Services" },
  { title: "Feedbacks", redirect: "/dashboard/doctor/feedbacks" },
  { title: "Wallet", redirect: "/dashboard/doctor/wallet" },
];

// const tabs = [
//   { title: "Appointments", redirect: "/dashboard/doctor/appointments" },
//   {
//     title: "Upload Prescription",
//     redirect: "/dashboard/doctor/upload-prescription",
//   },
//   { title: "Feedbacks", redirect: "/dashboard/doctor/feedbacks" },
// ];

// export default function DoctorDash() {
//   return <Dashboard tabs={tabs} />;
// }

export default function DoctorDash() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
          <div className={styles.container}>
            <Sidebar tabs={tabs} />
            <div className={styles.mainContent}>
              <Topbar />
              <div className={styles.contentArea}></div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
