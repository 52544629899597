import React from "react";
import DashTabs from "../../DashTabs/DashTabs";
import styles from "./Dashboard.module.css";


export default function Dashboard({ tabs }) {
  return (
    <div className={styles.container}>
      {/* <Navbar /> */}
      <DashTabs tabs={tabs} />
    </div>
  );
}





// export default function Dashboard({ tabs }) {
//   return (
//     <div className={styles.container}>
//           <Outlet />
//     </div>
//   );
// }

// export default function Dashboard({ tabs }) {
//   return (
//     <div className={styles.container}>
//       <Navbar />
//       <div className={styles.mainContent}>
//         <Sidebar tabs={tabs} />
//         <div className={styles.contentArea}>
//           <Outlet />{"Welcome to Admin Dashboard"}
//         </div>
//       </div>
//     </div>
//   );
// }
