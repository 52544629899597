import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Topbar from "../../Common/Topbar/Topbar";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { ColorModeContext, useMode } from "../../../../theme";
import {
  CssBaseline,
  ThemeProvider,
  Typography,
  Box,
  Fab,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import styles from "./Wallet.module.css";

import { tabs } from "../Doctor";

const BillingHistory = () => {
  const [theme, colorMode] = useMode();
  const [rows] = useState([
    {
      id: 1,
      firstName: "Jon",
      lastName: "Snow",
      status: "Paid",
      billingDate: "2024-09-01",
      amount: 120.5,
    },
    {
      id: 2,
      firstName: "Arya",
      lastName: "Stark",
      status: "Pending",
      billingDate: "2024-09-02",
      amount: 98.75,
    },
    {
      id: 3,
      firstName: "Tyrion",
      lastName: "Lannister",
      status: "Paid",
      billingDate: "2024-09-03",
      amount: 200.45,
    },
    {
      id: 4,
      firstName: "Daenerys",
      lastName: "Targaryen",
      status: "Pending",
      billingDate: "2024-09-04",
      amount: 150.0,
    },
    {
      id: 5,
      firstName: "Sansa",
      lastName: "Stark",
      status: "Paid",
      billingDate: "2024-09-05",
      amount: 220.5,
    },
    {
      id: 6,
      firstName: "Bran",
      lastName: "Stark",
      status: "Paid",
      billingDate: "2024-09-06",
      amount: 99.99,
    },
    {
      id: 7,
      firstName: "Jaime",
      lastName: "Lannister",
      status: "Pending",
      billingDate: "2024-09-07",
      amount: 85.75,
    },
    {
      id: 8,
      firstName: "Cersei",
      lastName: "Lannister",
      status: "Paid",
      billingDate: "2024-09-08",
      amount: 130.45,
    },
    {
      id: 9,
      firstName: "Samwell",
      lastName: "Tarly",
      status: "Pending",
      billingDate: "2024-09-09",
      amount: 45.25,
    },
    {
      id: 10,
      firstName: "Brienne",
      lastName: "of Tarth",
      status: "Paid",
      billingDate: "2024-09-10",
      amount: 170.0,
    },
  ]);

  const columns = [
    {
      field: "invoice",
      headerName: "Invoice",
      width: 150,
      renderCell: (params) => (
        <Button
          href={`/dummy-invoice-${params.row.id}.pdf`}
          target="_blank"
          variant="contained"
          size="small"
        >
          View PDF
        </Button>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span style={{ color: params.row.status === "Paid" ? "green" : "red" }}>
          {params.row.status}
        </span>
      ),
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      width: 180,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "billingDate",
      headerName: "Billing Date",
      width: 160,
      valueGetter: (params) => {
        const date = new Date(params.row.billingDate);
        return `${date.getDate().toString().padStart(2, "0")}-${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 160,
      valueGetter: (params) => `$${params.row.amount.toFixed(2)}`,
    },
  ];

  const totalEarnings = rows.reduce((sum, row) => sum + row.amount, 0);

  const navigate = useNavigate(); // Initialize navigate

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          <Sidebar tabs={tabs} sx={{ width: "250px", height: "910px" }} />
          <Box sx={{ flexGrow: 1, marginLeft: "250px" }}>
            <Topbar />

            {/* Redeem Button */}
            <box className = {styles.redeemButton}>
            <Button
              variant="contained"
              className={styles.redeem}
              onClick={() => navigate('/dashboard/redeem')} // Handle navigation
            >
              Redeem
            </Button>
            </box>

            {/* Fixed box for total pricing in the top-right corner */}
          <Box
            sx={{
              position: "fixed",
              top: "110px",
              right: "30px",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
            }}
          >
            
            <Typography variant="h2">
            Total Earnings:
            </Typography>
            <Typography variant="h3" 
              sx={{ fontWeight: "bold" }}>
                ${totalEarnings.toFixed(2)}
            </Typography>
            </Box>

            <Box
              sx={{
                marginLeft: "50px",
                marginRight: "50px",
                marginTop: "75px",
              }}
            >
              {/* Billing History Header */}
              <Typography variant="h3" className="table-header">
                Billing History
              </Typography>

              {/* DataGrid Table */}
              <Paper className="data-grid">
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  sx={{
                    border: 0,
                    fontSize: 16,
                    height: 600, // Set a fixed height
                    "& .MuiDataGrid-columnHeaders": {
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                    "& .MuiDataGrid-cell": {
                      fontSize: 16,
                      textAlign: "center",
                    },
                    "& .MuiDataGrid-row": {
                      borderBottom: "1px solid #ddd",
                    },
                    "& .MuiDataGrid-cellContent": {
                      justifyContent: "center",
                      display: "flex",
                    },
                  }}
                />
              </Paper>

              {/* Delete Button */}
              <Fab
                color="error"
                aria-label="delete"
                className="fab-delete"
                onClick={() => alert("Delete Action")}
              >
                <DeleteIcon />
              </Fab>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default BillingHistory;
