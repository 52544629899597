import React from "react";
import "./Page404.css";

export default function Page404() {
  return (
    <div>
      <section className="page404 section">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              {/* <img src="../../../public/img/logo.png" alt="#" /> */}
              <div className="section-title">
                <h1>404 Page not found</h1>
                <button className="btn" type="button" name="back">
                  Go back To Home
                </button>
                <p>Oops. Something went wrong.</p>
                <h5>
                  " We’re sorry, the page you have looked for does not exist in
                  our website! Maybe go to our home page or try to use a search?
                  "
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
