import React from 'react';
import styles from './SectionSix.module.css';

const SectionSix = () => {
  // Dummy data for the right side of the second container
  const dummyData = [
    'Consultation For 50+ Diseases Across India',
    'Medical Expertise With Technology',
    'Assisted Surgery Experience',
    'Post Surgery Care',
    '1000+ Happy Patients'
  ];

  return (
    <div className={styles.sectionSix}>
      {/* First Container */}
      <div className={styles.containerOne}>
        <div className={styles.leftSide}>
          <h1 className={styles.heading}>Healthcare Service that's gonna shake the game rules up.</h1>
          <button className={styles.button}>Learn More</button>
        </div>
        <div className={styles.rightSide}>
          <img
            src="/sectionSix1.png"
            alt="Placeholder"
            className={styles.image}
          />
        </div>
      </div>

      {/* Second Container */}
      <div className={styles.containerTwo}>
        <div className={styles.leftSide}>
          <h2 className={styles.headingWithUnderline}>Why Aspatt Healthcare..?</h2>
          <p className={styles.paragraph}>
          Delivering Seamless Surgical 
          Experience in India
          </p>
          <button className={styles.button1}>Book Appointment</button>
        </div>
        <div className={styles.rightSide1}>
          {dummyData.map((item, index) => (
            <div key={index} className={styles.numberedLine}>
                <span className={styles.number}>{`0${index + 1}`}</span>
                <h3 className={styles.dummyLine}>{item}</h3>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionSix;
