import React from "react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";

const BlogsPage = () => {
  return (
    <div>
      <Header />
      BlogsPage
      <Footer />
    </div>
  );
};

export default BlogsPage;
