import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import styles from "../../Common/Dashboard.module.css"; // Create a CSS module for styling

export default function Sidebar({ tabs }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div
        className={styles.sidebar}
        style={{ width: isCollapsed ? "80px" : "250px" }}
      >
        <div
          className={styles.header}
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <MenuOutlinedIcon style={{ color: "#ffffff" }} />
          </IconButton>
          {!isCollapsed && (
            <>
              <img
                alt="profile-user"
                width="100px"
                height="100px"
                src="/img/logo1.png"
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  margin: "10px 0",
                }}
              />
              <Typography variant="h6" color="#ffffff" fontWeight="bold">
                ASPATT HEALTHCARE
              </Typography>
            </>
          )}
        </div>
        <List>
          {tabs.map((tab, index) => (
            <ListItem
              button
              key={index}
              onClick={() => navigate(tab.redirect)}
              className={styles.listItem}
            >
              {tab.icon && (
                <div
                  style={{
                    marginRight: isCollapsed ? "0" : "10px",
                    color: "white",
                  }}
                >
                  {tab.icon}
                </div>
              )}
              {!isCollapsed && (
                <ListItemText
                  primary={tab.title}
                  primaryTypographyProps={{ style: { color: "#ffffff" } }}
                />
              )}
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className={styles.mainContent}
        style={{ marginLeft: isCollapsed ? "80px" : "250px" }}
      >
        {/* Other content goes here, dynamically adjusted based on sidebar state */}
      </div>
    </div>
  );
}
