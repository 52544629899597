import React, { useState } from "react";
import styles from "./Appointment_Form.module.css"; // Import the CSS module
import api from "../../../api"; // Import your API module
import { useAuth } from "../../../AuthContext";

const BookAppointmentForm = ({
  heading = "Enquiry Here",
  hospitalList = ["City Hospital", "General Hospital", "Healthcare Clinic"],
  doctorList = ["Dr. Smith", "Dr. John", "Dr. Adams"],
  onSubmit,
}) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    description: "",
  });
  const { setAlert, setAlertMsg, setAlertType } = useAuth();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    try {
      const res = await api.enquiry(formData); 
      console.log("Appointment booked successfully:", res.data);
      setAlertMsg(res.data.msg);
      setAlertType("success");
      setAlert(true);
    } catch (error) {
      console.error("Error booking appointment:", error); 
    }
    if (onSubmit) {
      onSubmit(formData); 
    }
  };

  return (
    <div className={styles.appointmentContainer}>
      <div className={styles.appointmentBox}>
        {/* Form Section */}
        <div className={styles.formSection}>
          <h2>{heading}</h2>
          <form onSubmit={handleFormSubmit}>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  value={formData.fullName}
                  onChange={(e) =>
                    setFormData({ ...formData, fullName: e.target.value })
                  }
                  required
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label>Phone Number</label>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                  required
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label>Email ID (Optional)</label>
                <input
                  type="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={styles.formGroup}>
              <label>Description</label>
              <textarea
                placeholder="Describe your symptoms or concerns"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required
              />
            </div>

            <button type="submit" className={styles.submitBtn}>
              Enquiry Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookAppointmentForm;
