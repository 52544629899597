import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { mockDataBookings } from "./mockData"; // Ensure this import is correct
import Header from "../../Common/Heading/Heading";
import styles from "./HospitalList.module.css";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { tabs } from "../Hospital"
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const PatientList = () => {
  const [theme, colorMode] = useMode();
  // const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "Booking ID", flex: 0.5 },
    { field: "PatientName", headerName: "Patient Name", flex: 1 },
    { field: "service", headerName: "Service", flex: 1 },
    { field: "BookingDate", headerName: "Booking Date", type: "date", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "Track", headerName: "Track Location", flex: 1 },
  ];

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.container}>
          {/* <Navbar /> */}
          <Sidebar tabs={tabs} />
          <div className={styles.mainContent}>
            <Topbar />
          </div>
          <Box m="20px" marginLeft="285px">
            <Header
              title="Patient List"
              subtitle="List of All Bookings and Their Details"
            />
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={mockDataBookings}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default PatientList;
