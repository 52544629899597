import React, { useEffect, useState } from "react";
import styles from "./hospitalActiveList.module.css"; // Import the CSS module
import api from "../../../../api"; // Adjust path according to your setup
import Header from "../Header"; // Adjust the path to your Header component
import Footer from "../Footer"; // Adjust the path to your Footer component

const HospitalsList = () => {
  const [hospitals, setHospitals] = useState([]);
  const [error, setError] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch active hospitals from the API
    const fetchHospitals = async () => {
      try {
        const response = await api.activeHospitals(); // Adjust API call to match your setup
        if (response.ok) {
          const data = await response.json();
          setHospitals(data);
        } else {
          const errorData = await response.json();
          setError(errorData.errorMsg || "Failed to fetch hospitals");
        }
      } catch (err) {
        setError("Error fetching hospital data");
      }
    };

    fetchHospitals();
  }, []);

  // Filter hospitals by city or search term (name/location)
  const filteredHospitals = hospitals.filter((hospital) => {
    const matchesCity = selectedCity ? hospital.city === selectedCity : true;
    const matchesSearch = searchTerm
      ? hospital.fname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        hospital.location.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    return matchesCity && matchesSearch;
  });

  return (
    <div>
      <Header />
      <div className={styles.filterContainer}>
        <div className={styles.filterLeft}>
          <label htmlFor="city">Select City:</label>
          <select
            id="city"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">All Cities</option>
            {hospitals.map((hospital) => (
              <option key={hospital._id} value={hospital.city}>
                {hospital.city}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.filterRight}>
          <input
            type="text"
            placeholder="Search by hospital name or location"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.container}>
        <h1 style={{textAlign: "left", fontWeight:"700", fontSize:"42px"}}>Our Hospitals</h1>
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.cardsContainer}>
          {filteredHospitals.length > 0 ? (
            filteredHospitals.map((hospital) => (
              <div key={hospital._id} className={styles.card}>
                {/* Left Section: Hospital Info */}
                <div className={styles.leftSection}>
                  <img
                    src= {hospital.imageUrl}
                    alt="Hospital"
                    className={styles.hospitalPhoto}
                  />
                  <div className={styles.hospitalInfo}>
                    <h2 style={{textAlign: "left", fontWeight:"700", fontSize:"32px"}}>{hospital.fname}</h2>
                    <p><strong>Location:</strong> {hospital.location}</p>
                    <p><strong>Rating:</strong> {hospital.rating} / 5</p>
                    <p><strong>Contact:</strong> {hospital.contact}</p>
                    <p><strong>Specialities:</strong> {hospital.specialities ? hospital.specialities.join(", ") : "No specialities listed"}</p>
                  </div>
                </div>

                {/* Right Section: Buttons */}
                <div className={styles.rightSection}>
                  <div className={styles.buttonsContainer}>
                    <button className={styles.bookButton}>Book Appointment</button>
                    <button className={styles.callButton}>
                      Call Us: {hospital.phoneNumber}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            !error && <p>No active hospitals available</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HospitalsList;
