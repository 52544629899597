import React, { useEffect, useState } from "react";
import styles from "./EditUserForm.module.css";
import { useSearchParams } from "react-router-dom";
import api from "../../../../api";
import { useAuth } from "../../../../AuthContext";
import { departments } from "../../Doctor/doctorDepartments";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function EditUserForm({ uid }) {
  const { setLoader, setAlert, setAlertMsg, setAlertType } = useAuth();
  const [searchParams] = useSearchParams();

  const [userType, setUserType] = useState("");
  const [fname, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [workDays, setWorkDays] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [fee, setFee] = useState("");
  const [department, setDepartment] = useState("");
  const [status, setStatus] = useState("deactive");
  const [image, setimage] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    
    if (files.length > 10) {
      alert("You can upload a maximum of 10 files.");
      return;
    }
  
    // Process files here (e.g., set them in state, upload to server, etc.)
    console.log(files);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.findUserWithStatus(uid, status);
        const userData = response.data;

        // Populate state with fetched user data
        setUserType(userData.userType || "");
        setFullName(userData.fname || "");
        setMobileNumber(userData.mobileNumber || "");
        setEmail(userData.email || "");
        setClinicName(userData.clinicName || "");
        setWorkDays(userData.workDays || []);
        setStatus(userData.status || "deactive");
        setFee(userData.fee || "");
        setDepartment(userData.department || "");
        setSpeciality(userData.speciality || "");

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [uid, status]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add the day to workDays with an empty time field
      setWorkDays((prevWorkDays) => [
        ...prevWorkDays,
        { day: value, time: "" },
      ]);
    } else {
      // Remove the day from workDays
      setWorkDays((prevWorkDays) =>
        prevWorkDays.filter((dayObj) => dayObj.day !== value)
      );
    }
  };

  const handleTimeSlotChange = (day, event) => {
    const { value } = event.target;
    setWorkDays((prevWorkDays) =>
      prevWorkDays.map((dayObj) =>
        dayObj.day === day ? { ...dayObj, time: value } : dayObj
      )
    );
  };

  const handleimageUrlChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setimage(file);
      setProfilePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoader(true);

      const formData = new FormData();
      formData.append("status", status);
      formData.append("userType", userType);
      formData.append("email", email);
      formData.append("speciality", speciality);
      formData.append("fee", fee);
      formData.append("department", department);
      formData.append("clinicName", clinicName);
      workDays.forEach((workDay, index) => {
        formData.append(`workDays[${index}][day]`, workDay.day);
        formData.append(`workDays[${index}][time]`, workDay.time);
      });
      if (image) {
        formData.append("image", image);
      }
      const files = document.querySelector('#documents').files;
      for (let i = 0; i < files.length; i++) {
        formData.append("documents", files[i]);
      }

      const res = await api.userUpdate(uid, formData);

      if (res.data.error) {
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        setAlertMsg(res.data.msg);
        setAlertType("success");
        setAlert(true);
      }
    } catch (error) {
      setAlertMsg(error?.response?.data?.errorMsg || "An Error Occurred!");
      setAlert(true);
    } finally {
      setLoader(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.formContainer}>
          <form className={styles.form} onSubmit={handleSubmit}>
            {/* User Type */}
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <label htmlFor="usertype">User Type</label>
              </div>
              <div className={styles.col75}>
                <input
                  className={styles.input}
                  type="text"
                  id="usertype"
                  name="usertype"
                  value={userType}
                  disabled
                />
              </div>
            </div>

            {/* Full Name */}
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <label htmlFor="fullname">Full Name</label>
              </div>
              <div className={styles.col75}>
                <input
                  className={styles.input}
                  type="text"
                  id="fullname"
                  name="fullname"
                  value={fname}
                  disabled
                />
              </div>
            </div>

            {/* Mobile Number */}
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <label htmlFor="mobileNumber">Mobile Number</label>
              </div>
              <div className={styles.col75}>
                <input
                  className={styles.input}
                  type="mobileNumber"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
            </div>

            {/* Email */}
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <label htmlFor="email">Email</label>
              </div>
              <div className={styles.col75}>
                <input
                  className={styles.input}
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            {/* Profile Picture */}
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <label htmlFor="image">Profile Picture</label>
              </div>
              <div className={styles.col75}>
                <input
                  type="file"
                  id="profilePicture"
                  name="file"
                  accept="image/*"
                  onChange={handleimageUrlChange}
                />
                {profilePreview && (
                  <div className={styles.previewContainer}>
                    <img
                      src={profilePreview}
                      alt="Profile Preview"
                      className={styles.previewImage}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <label htmlFor="documents">Upload Documents</label>
              </div>
              <div className={styles.col75}>
              <input
                type="file"
                name="documents"
                id="documents"
                accept=".pdf,.jpg,.jpeg"
                multiple
                placeholder="Upload Documnets"
                onChange={(event) => handleFileUpload(event)}
              />
              </div>
            </div>

            {/* Doctor Specific Fields */}
            {userType === "Doctor" && (
              <>
                {/* Department */}
                <div className={styles.rrow}>
                  <div className={styles.col25}>
                    <label htmlFor="department">Department</label>
                  </div>
                  <div className={styles.col75}>
                    <select
                      className={styles.input}
                      id="department"
                      name="department"
                      value={department}
                      onChange={(event) => setDepartment(event.target.value)}
                      required
                    >
                      {departments.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={styles.rrow}>
                  <div className={styles.col25}>
                    <label htmlFor="clinicName">Clinic Name</label>
                  </div>
                  <div className={styles.col75}>
                    <input
                      className={styles.input}
                      type="text"
                      id="clinicName"
                      name="clinicName"
                      value={clinicName}
                      disabled
                    />
                  </div>
                </div>

                {/* Speciality */}
                <div className={styles.rrow}>
                  <div className={styles.col25}>
                    <label htmlFor="speciality">Speciality</label>
                  </div>
                  <div className={styles.col75}>
                    <input
                      className={styles.input}
                      type="text"
                      id="speciality"
                      name="speciality"
                      value={speciality}
                      onChange={(event) => setSpeciality(event.target.value)}
                      placeholder="Speciality ..."
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>

                {/* Fee */}
                <div className={styles.rrow}>
                  <div className={styles.col25}>
                    <label htmlFor="fee">Fee</label>
                  </div>
                  <div className={styles.col75}>
                    <input
                      className={styles.input}
                      type="number"
                      id="fee"
                      name="fee"
                      value={fee}
                      onChange={(event) => setFee(event.target.value)}
                      placeholder="Appointment fee..."
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </>
            )}

            {/* Work Days */}
            <div className={styles.rrow}>
              <div className={styles.col25}>
                <p className={styles.workdaysLabel}>Work Days</p>
              </div>
              <div className={styles.col75}>
                {days.map((day) => (
                  <div key={day} className={styles.checkboxItem}>
                    <label className={styles.label}>
                      <input
                        type="checkbox"
                        value={day}
                        checked={workDays.some((dayObj) => dayObj.day === day)}
                        onChange={handleCheckboxChange}
                      />
                      {day}
                      {workDays.some((dayObj) => dayObj.day === day) && (
                        <input
                          type="text"
                          className={styles.timeInput}
                          value={
                            workDays.find((dayObj) => dayObj.day === day)
                              ?.time || ""
                          }
                          onChange={(e) => handleTimeSlotChange(day, e)}
                          placeholder="Time (e.g., 9am to 12pm)"
                        />
                      )}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Submit Button */}
            <div className={styles.rrow}>
              <button type="submit" className={styles.submitBtn}>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
