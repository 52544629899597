import React from "react";
import Dashboard from "../Common/Dashboard";
import Sidebar from "../Common/Sidebar/Sidebar";
import styles from "../Common/Dashboard.module.css";
import Topbar from "../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

// Import Icons
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import FormIcon from "@mui/icons-material/Assignment"; // Placeholder for Enquiry Form

export const tabs = [
  { title: "Home", redirect: "/dashboard/admin/", icon: <HomeIcon /> },
  {
    title: "List of Doctors",
    redirect: "/dashboard/admin/doc-list",
    icon: <ListAltIcon />,
  },
  {
    title: "List of Hospitals",
    redirect: "/dashboard/admin/hospital-list",
    icon: <ListAltIcon />,
  },
  {
    title: "Verify User",
    redirect: "/dashboard/admin/verify-user",
    icon: <VerifiedUserIcon />,
  },
  {
    title: "Book Appointment List",
    redirect: "/dashboard/admin/Book_Appointment_list",
    icon: <CalendarTodayIcon />,
  },
  {
    title: "Enquiry Form List",
    redirect: "/dashboard/admin/Enqiry_Form_list",
    icon: <FormIcon />,
  },
  {
    title: "Feedbacks",
    redirect: "/dashboard/admin/feedbacks",
    icon: <FeedbackIcon />,
  },
  {
    title: "List of Staffs",
    redirect: "/dashboard/admin/staff-list",
    icon: <PeopleIcon />,
  },
  {
    title: "Generate Stats",
    redirect: "/dashboard/admin/generate-stats",
    icon: <BarChartIcon />,
  },
];

export default function AdminDash() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
          <div className={styles.container}>
            <Sidebar tabs={tabs} />
            <div className={styles.mainContent}>
              <Topbar />
              <div className={styles.contentArea}>
                <Dashboard tabs={tabs} />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
