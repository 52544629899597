import React, { useState, useEffect } from 'react';
import styles from './Redeem.module.css';
import mockData from './mockdataRedeem';
import { tabs } from "../Doctor";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const Redeem = () => {
    const [theme, colorMode] = useMode();
    const [form, setForm] = useState({
        patientName: '',
        dob: '',
        contact: '',
        referringDoctor: '',
        reason: '',
        specialist: '',
        dateOfReferral: '',
        notes: ''
    });

    const [referralHistory, setReferralHistory] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        setReferralHistory(mockData);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newReferral = {
            ...form,
            id: referralHistory.length + 1,
            dateOfReferral: new Date(form.dateOfReferral).toISOString().split('T')[0] // Format date
        };

        setReferralHistory(prevHistory => [newReferral, ...prevHistory].sort((a, b) => new Date(b.dateOfReferral) - new Date(a.dateOfReferral)));
        setForm({
            patientName: '',
            dob: '',
            contact: '',
            referringDoctor: '',
            reason: '',
            specialist: '',
            dateOfReferral: '',
            notes: ''
        });
    };

    const handleCardClick = (referral) => {
        setSelectedCard(referral);
    };

    const handleCloseDialog = () => {
        setSelectedCard(null);
    };

    return (
        <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          {/* <Navbar /> */}<Sidebar tabs={tabs} />
          <div className={styles.mainContent1}><Topbar /></div>
        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Referral Dashboard</h1>
                <div className={styles.userInfo}>
                    <img src="profile-pic-url" alt="Profile" className={styles.profilePic} />
                    <span className={styles.username}>Dr. John Doe</span>
                </div>
            </header>

            <div className={styles.mainContent}>
                <div className={`${styles.formContainer} ${selectedCard ? styles.hidden : ''}`}>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <h2>New Referral</h2>
                        <div className={styles.formGroup}>
                            <label>Patient Name</label>
                            <input
                                type="text"
                                name="patientName"
                                value={form.patientName}
                                onChange={handleChange}
                                placeholder="Enter patient name"
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Date of Birth</label>
                            <input
                                type="date"
                                name="dob"
                                value={form.dob}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Contact Information</label>
                            <input
                                type="text"
                                name="contact"
                                value={form.contact}
                                onChange={handleChange}
                                placeholder="Enter contact information"
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Referring Doctor</label>
                            <input
                                type="text"
                                name="referringDoctor"
                                value={form.referringDoctor}
                                onChange={handleChange}
                                placeholder="Enter referring doctor"
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Referral Reason</label>
                            <input
                                type="text"
                                name="reason"
                                value={form.reason}
                                onChange={handleChange}
                                placeholder="Enter referral reason"
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Preferred Specialist/Clinic</label>
                            <input
                                type="text"
                                name="specialist"
                                value={form.specialist}
                                onChange={handleChange}
                                placeholder="Enter specialist/clinic"
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Date of Referral</label>
                            <input
                                type="date"
                                name="dateOfReferral"
                                value={form.dateOfReferral}
                                onChange={handleChange}
                                min={new Date().toISOString().split('T')[0]} // Restrict past dates
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Notes</label>
                            <textarea
                                name="notes"
                                value={form.notes}
                                onChange={handleChange}
                                placeholder="Additional notes"
                            />
                        </div>
                        <div className={styles.actions}>
                            <button type="submit" className={styles.submitButton}>Submit</button>
                            <button type="button" className={styles.cancelButton} onClick={() => setSelectedCard(null)}>Cancel</button>
                        </div>
                    </form>
                </div>

                <div className={`${styles.historyContainer} ${selectedCard ? styles.hidden : ''}`}>
                    <h2>Referral History</h2>
                    <input
                        type="text"
                        placeholder="Search referrals..."
                        className={styles.searchBar}
                    />
                    <div className={styles.cardContainer}>
                        {referralHistory.map(referral => (
                            <div key={referral.id} className={styles.card} onClick={() => handleCardClick(referral)}>
                                <div className={styles.cardContent}>
                                    <h3>{referral.patientName}</h3>
                                    <p>Specialist: {referral.specialist}</p>
                                    <p>Date: {referral.dateOfReferral}</p>
                                    <p>Status: {referral.status || 'Pending'}</p>
                                </div>
                                <button className={styles.viewButton}>View Details</button>
                            </div>
                        ))}
                    </div>
                </div>

                {selectedCard && (
                    <div className={styles.dialogOverlay}>
                        <div className={styles.dialog}>
                            <h2>Referral Details</h2>
                            <div className={styles.details}>
                                <p><strong>Patient Name:</strong> {selectedCard.patientName}</p>
                                <p><strong>Date of Birth:</strong> {selectedCard.dob}</p>
                                <p><strong>Contact Information:</strong> {selectedCard.contact}</p>
                                <p><strong>Referring Doctor:</strong> {selectedCard.referringDoctor}</p>
                                <p><strong>Referral Reason:</strong> {selectedCard.reason}</p>
                                <p><strong>Preferred Specialist/Clinic:</strong> {selectedCard.specialist}</p>
                                <p><strong>Date of Referral:</strong> {selectedCard.dateOfReferral}</p>
                                <p><strong>Notes:</strong> {selectedCard.notes}</p>
                            </div>
                            <button className={styles.closeButton} onClick={handleCloseDialog}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default Redeem;
