import React, { useState } from "react";
import styles from "./Filters.module.css";

const Filters = ({ filterData }) => {
  const [sortedPrices, setSortedPrices] = useState(filterData.price);
  const [sortOrder, setSortOrder] = useState("lowToHigh"); // Default sort order

  // Function to sort prices
  const sortPrices = (order) => {
    let sorted;
    if (order === "lowToHigh") {
      sorted = [...filterData.price].sort((a, b) => {
        const aPrice = parseFloat(a.replace(/[^0-9.-]+/g, ""));
        const bPrice = parseFloat(b.replace(/[^0-9.-]+/g, ""));
        return aPrice - bPrice;
      });
    } else {
      sorted = [...filterData.price].sort((a, b) => {
        const aPrice = parseFloat(a.replace(/[^0-9.-]+/g, ""));
        const bPrice = parseFloat(b.replace(/[^0-9.-]+/g, ""));
        return bPrice - aPrice;
      });
    }
    setSortedPrices(sorted);
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortOrder(value);
    sortPrices(value);
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filter}>
        <label className={styles.flabel}>Area</label>
        <select>
          <option value="">Select Area</option>
          {filterData.area.map((area, index) => (
            <option key={index} value={area}>
              {area}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.filter}>
        <label className={styles.flabel}>Price</label>
        <select onChange={handleSortChange} value={sortOrder}>
          <option value="lowToHigh">Low to High</option>
          <option value="highToLow">High to Low</option>
        </select>
        {sortedPrices.length > 0 && (
          <select>
            <option value="">Select Price</option>
            {sortedPrices.map((price, index) => (
              <option key={index} value={price}>
                {price}
              </option>
            ))}
          </select>
        )}
      </div>

      <div className={styles.filter}>
        <label className={styles.flabel}>Specialty</label>
        <select>
          <option value="">Select Specialty</option>
          {filterData.specialty.map((specialty, index) => (
            <option key={index} value={specialty}>
              {specialty}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.filter}>
        <label className={styles.flabel}>Disease</label>
        <select>
          <option value="">Select Disease</option>
          {filterData.disease.map((disease, index) => (
            <option key={index} value={disease}>
              {disease}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.filter}>
        <label className={styles.flabel}>Insurance</label>
        <select>
          <option value="">Select Insurance</option>
          {filterData.insurance.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.filter}>
        <label className={styles.flabel}>Government Job</label>
        <select>
          <option value="">Select Government Job</option>
          {filterData.governmentJob.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Filters;
