import React, { useState, useEffect } from 'react';
import styles from './ContactUs.module.css';
import { FaPlus, FaMinus } from 'react-icons/fa';
import BookAppointment from "../sections/Appointment_Form";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles


// Dynamic FAQ Item Component
const FAQItem = ({ index, question, answer, isActive, toggleFaq }) => {
    return (
        <div className={`${styles.faqItem} ${isActive ? styles.active : ''}`}>
            <div className={styles.faqQuestion} onClick={() => toggleFaq(index)}>
                <span>{isActive ? <FaMinus /> : <FaPlus />}</span>
                {question}
            </div>
            {isActive && <p className={styles.faqAnswer}>{answer}</p>}
        </div>
    );
};

// Dynamic Card Component
const Card = ({ icon, title, subtitle }) => {
    return (
        <div className={styles.card}>
            <div className={styles.icon}>{icon}</div>
            <div>
                <h3>{title}</h3>
                <p>{subtitle}</p>
            </div>
        </div>
    );
};

// Google Map Component
const GoogleMap = ({ src, title }) => {
    return (
        <div className={styles.mapCard}>
            <iframe
                src={src}
                width="100%"
                height="200"
                allowFullScreen=""
                loading="lazy"
                title={title}
            ></iframe>
        </div>
    );
};

const ContactUsPage = () => {
    const [faqToggle, setFaqToggle] = useState({});

    const toggleFaq = (index) => {
        setFaqToggle((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const faqs = [
        { question: 'How can I book an appointment?', answer: 'You can book an appointment through our online form or call us directly.' },
        { question: 'What are your office hours?', answer: 'Our office hours are from Monday to Friday, 9 AM to 5 PM.' },
        { question: 'Do you offer virtual consultations?', answer: 'Yes, we offer virtual consultations via phone or video.' }
    ];

    const cards = [
        { icon: '📍', title: 'Office', subtitle: 'Mumbai' },
        { icon: '⏰', title: 'Office Timings', subtitle: 'Monday to Friday' },
        { icon: '✉️', title: 'Email Address', subtitle: '123@gmail.com' },
        { icon: '📞', title: 'Phone Number', subtitle: '123456789' },
        { icon: '💬', title: 'Live Chat', subtitle: '123456789' }
    ];
    useEffect(() => {
        AOS.init({ duration: 10000 }); // Initialize AOS with a custom duration
      }, []);


    return (
        <div className={styles.container}>
            {/* First Section */}
            <div className={styles.headerSection} data-aos="zoom-in">
                <h1 className={styles.header}>Get in Touch</h1>
            </div>

            {/* Second Section */}
            <div className={styles.cardsAndForm}>
                <div className={styles.cards} data-aos="zoom-in">
                    {/* Google Map Card */}
                    <GoogleMap 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.1160993201!2d72.7410973771439!3d19.082197839134187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b63fffc6e3b7%3A0x823c6b8c7bfe42b9!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1631523054556!5m2!1sen!2sin"
                        title="Mumbai Location"
                    />
                    {/* Other Cards */}
                    {cards.map((card, index) => (
                        <Card key={index} icon={card.icon} title={card.title} subtitle={card.subtitle} />
                    ))}
                </div>

                <div className={styles.bookAppointmentSection} data-aos="fade-left">
                    <BookAppointment />
                </div>
            </div>

            {/* Third Section - FAQ */}
            <div className={styles.faqSection}>
                <div className={styles.faqLeft} data-aos="zoom-out">
                    <h2>Frequently Ask Question</h2>
                    <p>We use only the best quality materials on the market in order to provide the best products to our patients.</p>
                </div>
                <div className={styles.faqRight} data-aos="zoom-out" data-aos-delay="300">
                    {faqs.map((faq, index) => (
                        <FAQItem 
                            key={index} 
                            index={index} 
                            question={faq.question} 
                            answer={faq.answer} 
                            isActive={faqToggle[index]} 
                            toggleFaq={toggleFaq}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;