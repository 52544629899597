import React from "react";
import "./Team.css";
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const Team = () => {
  return (
    <section id="doctors" className="container doctors section">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title" data-aos="fade-up">
            <h2>We Offer The Best Doctors To Improve Your Health</h2>
            <img src="img/section-img.png" alt="/social" />
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit praesent
              aliquet. pretiumts
            </p> */}
          </div>
        </div>
      </div>

      <div className="row gy-4">
        <div
          className="team-outer col-lg-4 col-md-6 col-sm-12"
          data-aos="fade-up"
        >
          <div className="team-member d-flex align-items-start">
            <div className="pic">
              <img src="doctors/dr4.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Dr. Vivek Sharma</h4>
              <span>Chief Medical Officer</span>
              <p>Explicabo voluptatem mollitia et repellat</p>
              <div className="social">
                <a href="/social">
                  <FaTwitter />
                </a>
                <a href="/social">
                  <FaFacebook />
                </a>
                <a href="/social">
                  <FaInstagram />
                </a>
                <a href="/social">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="team-outer col-lg-4 col-md-6 col-sm-12"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="team-member d-flex align-items-start">
            <div className="pic">
              <img src="doctors/dr7.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Dr. Meera Iyer</h4>
              <span>Cardiology</span>
              <p>Quisquam facilis cum velit laborum corrupti</p>
              <div className="social">
                <a href="/social">
                  <FaTwitter />
                </a>
                <a href="/social">
                  <FaFacebook />
                </a>
                <a href="/social">
                  <FaInstagram />
                </a>
                <a href="/social">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="team-outer col-lg-4 col-md-6 col-sm-12"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="team-member d-flex align-items-start">
            <div className="pic">
              <img src="doctors/dr3.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Dr. Rajesh Malhotra</h4>
              <span>Cardiology</span>
              <p>Quisquam facilis cum velit laborum corrupti</p>
              <div className="social">
                <a href="/social">
                  <FaTwitter />
                </a>
                <a href="/social">
                  <FaFacebook />
                </a>
                <a href="/social">
                  <FaInstagram />
                </a>
                <a href="/social">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="team-outer col-lg-4 col-md-6 col-sm-12"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="team-member d-flex align-items-start">
            <div className="pic">
              <img src="doctors/dr6.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Dr. Pooja Menon</h4>
              <span>Neurosurgeon</span>
              <p>Dolorum tempora officiis odit laborum officiis</p>
              <div className="social">
                <a href="/social">
                  <FaTwitter />
                </a>
                <a href="/social">
                  <FaFacebook />
                </a>
                <a href="/social">
                  <FaInstagram />
                </a>
                <a href="/social">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="team-outer col-lg-4 col-md-6 col-sm-12"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="team-member d-flex align-items-start">
            <div className="pic">
              <img src="doctors/dr5.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Dr. Suresh Patil</h4>
              <span>Neurosurgeon</span>
              <p>Dolorum tempora officiis odit laborum officiis</p>
              <div className="social">
                <a href="/social">
                  <FaTwitter />
                </a>
                <a href="/social">
                  <FaFacebook />
                </a>
                <a href="/social">
                  <FaInstagram />
                </a>
                <a href="/social">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="team-outer col-lg-4 col-md-6 col-sm-12"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="team-member d-flex align-items-start">
            <div className="member-info">
              <h4>Explore</h4>
              <span> </span>
              <p>345+ Choices</p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Team;
