import React, { useEffect, useState } from "react";
import styles from "./docActiveList.module.css"; // Import the CSS module
import api from "../../../../api";
import Header from "../Header";
import Footer from "../Footer";

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [error, setError] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch active doctors from the API
    const fetchDoctors = async () => {
      try {
        const response = await api.activeDoctors();
        if (response.ok) {
          const data = await response.json();
          setDoctors(data);
        } else {
          const errorData = await response.json();
          setError(errorData.errorMsg || "Failed to fetch doctors");
        }
      } catch (err) {
        setError("Error fetching doctor data");
      }
    };

    fetchDoctors();
  }, []);

  // Filter doctors by city or search term (name/disease)
  const filteredDoctors = doctors.filter((doctor) => {
    const matchesCity = selectedCity ? doctor.city === selectedCity : true;
    const matchesSearch = searchTerm
      ? doctor.fname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doctor.speciality.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    return matchesCity && matchesSearch;
  });

  return (
    <div>
      <Header />
      <div className={styles.filterContainer}>
        <div className={styles.filterLeft}>
          <label htmlFor="city">Select City:</label>
          <select
            id="city"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">All Cities</option>
            {doctors.map((doctor) => (
              <option key={doctor._id} value={doctor.city}>
                {doctor.city}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.filterRight}>
          <input
            type="text"
            placeholder="Search by disease or doctor name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.container}>
        <h1 style={{textAlign: "left", fontWeight:"700", fontSize:"42px"}}>Our Doctors</h1>
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.cardsContainer}>
        {filteredDoctors.length > 0 ? (
          filteredDoctors.map((doctor) => (
            <div key={doctor._id} className={styles.card}>
              {/* Left Section: Doctor Photo and Info */}
              <div className={styles.leftSection}>
                <img
                  src={doctor.imageUrl}
                  alt="Doctor"
                  className={styles.doctorPhoto}
                />
                <div className={styles.doctorInfo}>
                  <h2 style={{textAlign: "left", fontWeight:"700", fontSize:"32px"}}>{doctor.fname}</h2>
                  <p><strong>Rating:</strong> {doctor.rating} / 5</p>
                  <p><strong>Specialization:</strong> {doctor.speciality}</p>
                  <p><strong>Years of Experience:</strong> {doctor.experience}</p>
                  <p><strong>Consultation Fee:</strong> ${doctor.fee}</p>
                  <p><strong>Clinic:</strong> {doctor.clinicName}, {doctor.address}</p>
                  <div>
                    <h3>Specialities</h3>
                    <p>{doctor.specialities ? doctor.specialities.join(", ") : "No specialities listed"}</p>
                  </div>
                </div>
              </div>

              {/* Right Section: Buttons */}
              <div className={styles.rightSection}>
                <div className={styles.buttonsContainer}>
                  <button className={styles.bookButton}>Book Appointment</button>
                  <button className={styles.callButton}>
                    Call Us: {doctor.phoneNumber}
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          !error && <p>No active doctors available</p>
        )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DoctorsList;
