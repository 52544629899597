import { Routes, Route } from "react-router-dom";
import Topbar from "../Common/Topbar/Topbar";
import Sidebar from "../Common/Sidebar/Sidebar";
import Dashboard from "./home";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../../theme";
import styles from "../Common/Dashboard.module.css";

export const tabs = [
  { title: "Home", redirect: "/dashboard/Ambulance" },
  { title: "Driver Details", redirect: "/dashboard/ambulance/DriverDetails" },
  { title: "Booking", redirect: "/dashboard/ambulance/Bookings" },
  { title: "Pricing", redirect: "/dashboard/ambulance/Pricing" },
  { title: "Contacts", redirect: "/dashboard/ambulance/contacts" },
];


function Ambulance() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
            <div className={styles.container}>
                <Sidebar tabs={tabs} />
                <div className={styles.mainContent}>
                  <Topbar />
            <Routes>
              <Route path="" element={<Dashboard />} />
            </Routes>
            </div>
            </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Ambulance;
