import React from "react";
import BookAppointment from "../sections/Book_Appointment"; // Import your form

const Footer = () => {
  return (
    <div>
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            {/* Left Section: Logo, Email, and Paragraph */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="footer-logo-email">
                <img
                  src="/aspatt-lgo-circle.png"
                  alt="Aspatt Healthcare Logo"
                  className="footer-logo"
                />
                <form
                  action="mail/mail.php"
                  method="get"
                  target="_blank"
                  className="newsletter-inner"
                >
                  <input
                    name="email"
                    placeholder="Email Address"
                    className="common-input"
                    required=""
                    type="email"
                  />
                  <button className="button">
                    <i className="icofont icofont-paper-plane"></i>
                  </button>
                </form>
                <p className="footer-paragraph">
                Aspatt Healthcare ensures seamless access to top-notch medical expertise of your health without the hassle.  
                </p>
                <ul className="social">
                  <li>
                    <a href="/social">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/social">
                      <i className="icofont-google-plus"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/social">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Footer Sections */}
            <div className="col-lg-9 col-md-12 col-12">
              <div className="footer-sections">
                {/* Quick Links Section */}
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h2>Quick Links</h2>
                    <ul>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          About Me
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Portfolio
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Surgery Section */}
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h2>Surgery</h2>
                    <ul>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Laser Surgery
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Laproscopy
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Ear
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Eye
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Plastic Surgery
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Legal Section */}
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h2>Legal</h2>
                    <ul>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Return Policy
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/social">
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                          Terms of Services
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Location Section */}
                <div className="col-lg-3 col-md-6 col-12 location-section">
                  <div className="single-footer f-link">
                    <h2>Location</h2>
                    <p className="location-details">
                      City Name
                      <br />
                      Exact Location Details
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Separator Line */}
        <div className="footer-separator"></div>

        {/* Additional Content */}
        <div className="footer-bottom">
          <div className="copyright-left">
            <span className="copyright-circle">©</span> 2024. ABC. All rights
            reserved.
          </div>
          <div className="footer-links-right">
            <a href="/social">Privacy Policy</a>
            <a href="/social">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </footer>
    <BookAppointment/>
    </div>
  );
};

export default Footer;