import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import styles from "./header.module.css";
import BookAppointment from "./Book_Appointment";
import AddLocationIcon from "@mui/icons-material/AddLocation";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // Initialize navigate

  // Track window width changes
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenPopup = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const location = useLocation();
  const isActive = (pathname) => location.pathname === pathname;

  return (
    <header className={styles.header}>
      <div className={styles.topSection}>
        {/* Person icon button for mobile view (<= 480px) */}
        {windowWidth <= 480 && (
          <div className={styles.iconContainer}>
            <IconButton
              aria-label="person"
              className={styles.iconButton}
              onClick={() => navigate("/signin")} // Add functionality as needed
            >
              <PersonIcon sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>

            <IconButton
              aria-label="add location"
              className={styles.iconButton2}
              onClick={() => console.log("Person Icon clicked!")} // Navigate to '/select-city' on click
            >
              <AddLocationIcon sx={{ color: "white", fontSize: "30px" }} />
              <span style={{ color: "white", fontSize: "14px" }}>
                Select City
              </span>
            </IconButton>
          </div>
        )}

        <div className={styles.logo}>
          <a href="/">
            <img className="head-logo" src="/Logo-1.png" alt="Logo" />
          </a>
        </div>

        {/* Render search and location input fields */}
        <div className={styles.searchFields}>
          <input
            className={styles.searchInput}
            type="text"
            placeholder="Search..."
          />
          {windowWidth > 480 && ( // Show location field only if width is greater than 480px
            <input
              className={styles.locationInput}
              type="text"
              placeholder="Location..."
            />
          )}
        </div>

        {/* Render buttons only if window width is greater than 480px */}
        {windowWidth > 480 && (
          <div className={styles.buttons}>
            <button className={styles.bookBtn} onClick={handleOpenPopup}>
              Book Appointment
            </button>
            {isOpen && (
              <BookAppointment onClose={handleClosePopup} immediate={true} />
            )}
            <button 
              className={styles.enquiryBtn} 
              onClick={() => window.location.href = '/Contact'}
            >
              Enquiry Here
            </button>
          </div>
        )}

        <div className={styles.menuIcon} onClick={toggleMenu}>
          &#9776;
        </div>
      </div>

      <nav
        className={`${styles.navbarSection} ${menuOpen ? styles.showMenu : ""}`}
      >
        <ul className={styles.navLinks}>
          <li className={isActive("/") ? styles.active : ""}>
            <a href="/">Home</a>
          </li>
          <li className={isActive("/find") ? styles.active : ""}>
            <a href="/find">Find</a>
          </li>
          <li className={isActive("/services") ? styles.active : ""}>
            <a>
              Services
              <i className="icofont-rounded-down" />
            </a>
            <ul className={styles.dropdown}>{/* Dropdown menu items */}</ul>
          </li>
          <li className={isActive("/about") ? styles.active : ""}>
            <a href="/about">About Us</a>
          </li>
          <li className={isActive("/blogs") ? styles.active : ""}>
            <a href="/blogs">Blogs</a>
          </li>
          <li className={isActive("/DoctorsList") ? styles.active : ""}>
            <a href="/DoctorsList">Our Doctors</a>
          </li>
          <li className={isActive("/HospitalList") ? styles.active : ""}>
            <a href="/HospitalList">Our Hospitals</a>
          </li>
          <li className={isActive("/contact") ? styles.active : ""}>
            <a href="/contact">Contact Us</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
