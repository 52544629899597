import React, { useState } from "react";
import "./NoCostEMI.css"; // Import the CSS file

const NoCostEMI = () => {
  const [selectedOption, setSelectedOption] = useState(""); // State for dropdown selection

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);

    // Automatically reset to default option after 2 seconds
    setTimeout(() => {
      setSelectedOption(""); // Reset back to default
    }, 2000);
  };

  return (
    <div className="vail-container">
      <div className="left-content-EMI">
        <h1 className="header">
          Avail No Cost EMI for surgeries of 50+ diseases across India
        </h1>
        <div className="stats">
          <span>400+ DOCTORS</span>
          <div className="divider"></div>
          <span>50+ Diseases</span>
          <div className="divider"></div>
          <span>45+ Cities</span>
        </div>
        <div className="buttons">
          <button className="enquiry-btn">Enquiry</button>

          {/* Dropdown for EMI options */}
          <select
            className="check-emi-dropdown"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="" disabled>
              Check EMI on Surgery
            </option>
            <option value="emi1">EMI for Minor Surgery</option>
            <option value="emi2">EMI for Major Surgery</option>
            <option value="emi3">EMI for Plastic Surgery</option>
          </select>
        </div>
      </div>

      <div className="right-content-EMI">
        <img
          src="/group-of-doctors.png"
          alt="Group of Doctors"
          className="doctors-image"
        />
      </div>
    </div>
  );
};

export default NoCostEMI;
