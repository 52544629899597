import React, { useEffect } from "react";
import styles from "./aboutUs.module.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

const AboutUs = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);
  return (
    <div className={styles.container}>
      {/* First Section: About Us */}
      <section className={styles.firstSection} data-aos="zoom-in">
        <h1>About Us</h1>
      </section>

      {/* Second Section: Our Mission */}
      <section className={styles.secondSection}>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>Our Mission</h2>
          <p className={styles.p1}>
            Our mission is to provide exceptional, compassionate care to every
            individual we serve. We believe in treating not just the illness,
            but the whole person, and we are dedicated to making a positive
            impact on the lives of our patients and their families.
          </p>
          <p className={styles.p1}>
            Our team is comprised of highly skilled and dedicated professionals,
            including doctors, nurses, specialists, and support staff, all
            committed to delivering the highest standard of care.
          </p>
          <p className={styles.p1}>
            Our facility is equipped with state-of-the-art technology and
            follows the latest advancements in medical practices to ensure you
            receive the best possible treatment. But beyond our expertise and
            technology, we pride ourselves on our human touch, understanding
            that healthcare is as much about empathy and connection as it is
            about medical knowledge.
          </p>
        </div>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/signIn.png" alt="Our Mission" />
        </div>
      </section>

      {/* Third Section: What Sets Us Apart */}
      <section className={styles.thirdSection}>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/about2.png" alt="What Sets Us Apart" />
        </div>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>What Sets Us Apart</h2>
          <p className={styles.p2}>
            Patient-Centered Care: We put you at the heart of everything we do.
            From your first visit to your ongoing care, we listen to your needs
            and preferences, ensuring that your healthcare journey is as
            comfortable and effective as possible.
          </p>
          <p className={styles.p2}>
            Expert Team: Our team of medical professionals includes
            [specializations or notable experts], who are leaders in their
            fields. Their combined experience and commitment to continuing
            education ensure that you receive the most up-to-date and effective
            treatments available.
          </p>
        </div>
      </section>

      {/* Fourth Section */}
      <section className={styles.fourthSection}>
        <p>
          Innovative Approach: We embrace the latest in medical research and
          technology to offer cutting-edge treatments. Our approach is holistic,
          integrating the best of traditional and modern medicine to support
          your overall well-being.
        </p>
        <p>
          Community Focused: As a locally rooted organization, we are deeply
          invested in the health of our community. We participate in community
          outreach programs and provide resources and education to promote
          wellness beyond our facility.
        </p>
      </section>

      {/* Fifth Section: Our Values */}
      <section className={styles.fifthSection}>
        <h2>Our Values</h2>
        <p className={styles.p3}>
          Compassion: We treat each patient with empathy and respect,
          understanding that healthcare can be a challenging experience. Our
          goal is to provide support and comfort throughout your care journey.
        </p>
        <p className={styles.p3}>
          Integrity: We are committed to transparency, honesty, and ethical
          practices in all aspects of our work. You can trust that we will
          always act in your best interest.
        </p>
        <p className={styles.p3}>
          Excellence: We strive for excellence in every facet of our
          organization, from the quality of care we provide to the efficiency of
          our operations. Our continuous improvement mindset ensures that we are
          always working to enhance our services.
        </p>
        <div className={styles.imageRow} data-aos="zoom-in">
          <img src="/about3.png" alt="Value 1" />
          <img src="/about4.png" alt="Value 2" />
        </div>
      </section>

      {/* Sixth Section */}
      <section className={styles.sixthSection} data-aos="zoom-in">
        <h3>
          Find the right care, doctor, and hospital right at your fingertips
        </h3>
        <h4>
          Find doctors and hospitals based on the factors that matter most to
          you.
        </h4>
        <button className={styles.bookButton}>Book Appointment</button>
      </section>
    </div>
  );
};

export default AboutUs;
