import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";

const Card2 = ({
  id,
  image,
  name,
  specialization,
  experience,
  qualifications,
  contact,
  availability,
  expertise,
  languages,
  location,
  onEdit,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [cardData, setCardData] = React.useState({
    id,
    image,
    name,
    specialization,
    experience,
    qualifications,
    contact,
    availability,
    expertise,
    languages,
    location,
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onEdit(cardData);
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    setCardData({
      ...cardData,
      [event.target.name]: event.target.value,
    });
  };

  // Handle key press events
  const handleKeyDown = (event) => {
    if (event.key === "Escape" || event.key === "Enter") {
      setIsEditing(false);
      if (event.key === "Enter") {
        handleSave();
      }
    }
  };

  return (
    <Card sx={{ position: "relative", maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={cardData.image}
          alt={cardData.name}
        />
        <CardContent>
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={cardData.name}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter name"
            />
          ) : (
            <Typography gutterBottom variant="h5" component="div">
              {cardData.name}
            </Typography>
          )}
          {isEditing ? (
            <input
              type="text"
              name="specialization"
              value={cardData.specialization}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter specialization"
            />
          ) : (
            <Typography gutterBottom variant="h6" component="div">
              {cardData.specialization}
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            <strong>Experience:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="experience"
                value={cardData.experience}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.experience
            )}{" "}
            <br />
            <strong>Qualifications:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="qualifications"
                value={cardData.qualifications}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.qualifications
            )}{" "}
            <br />
            <strong>Contact:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="contact"
                value={cardData.contact}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.contact
            )}{" "}
            <br />
            <strong>Availability:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="availability"
                value={cardData.availability}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.availability
            )}{" "}
            <br />
            <strong>Expertise:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="expertise"
                value={cardData.expertise}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.expertise
            )}{" "}
            <br />
            <strong>Languages:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="languages"
                value={cardData.languages}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.languages
            )}{" "}
            <br />
            <strong>Location:</strong>{" "}
            {isEditing ? (
              <input
                type="text"
                name="location"
                value={cardData.location}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            ) : (
              cardData.location
            )}{" "}
            <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {isEditing ? (
          <Button size="small" color="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Fab
            color="secondary"
            aria-label="edit"
            sx={{ position: "absolute", bottom: "8px", right: "8px" }}
            onClick={handleEdit}
          >
            <EditIcon />
          </Fab>
        )}
      </CardActions>
    </Card>
  );
};

export default Card2;
