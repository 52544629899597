import React from "react";
import Slider from "react-slick";
import PhoneIcon from "@mui/icons-material/Phone"; // Import the phone icon
import "./LandingPage.css"; // Import the CSS file for styling

const LandingPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  };

  return (
    <div className="landing-container">
      {/* Slider on the left side */}
      <div className="slider-box">
        <Slider {...settings}>
          <div className="slide">
            <div className="slide-text">
              <h2>Comprehensive Diagnostics</h2>
              <p>
                Get accurate and timely medical diagnostics with our advanced
                technology. Your health is our priority.
              </p>
            </div>
            <div className="slide-image">
              <img src="img/slider.png" alt="Slide 1" />
            </div>
          </div>
          <div className="slide">
            <div className="slide-text">
              <h2>24/7 Ambulance Services</h2>
              <p>
                Quick response times with fully equipped ambulances to handle
                any emergency, anytime, anywhere.
              </p>
            </div>
            <div className="slide-image">
              <img src="img/slider2.png" alt="Slide 2" />
            </div>
          </div>
          <div className="slide">
            <div className="slide-text">
              <h2>Trusted Pharmacy Solutions</h2>
              <p>
                A wide range of authentic medicines and health products
                delivered to your doorstep with utmost care.
              </p>
            </div>
            <div className="slide-image">
              <img src="img/slider3.png" alt="Slide 3" />
            </div>
          </div>
          <div className="slide">
            <div className="slide-text">
              <h2>Multi-Specialty Hospital Care</h2>
              <p>
                Expert medical care across various specialties. Dedicated to
                improving lives with world-class facilities.
              </p>
            </div>
            <div className="slide-image">
              <img src="img/slider4.png" alt="Slide 4" />
            </div>
          </div>
        </Slider>
      </div>

      {/* Right-hand side content */}
      <div className="right-content">
        <h1>Get ready for your best ever healthcare Services!</h1>
        <div className="button-group">
          {/* <button className="login-btn">
            <a href="/signin">Log In</a>
          </button> */}
          <button className="login-btn" onClick={() => window.location.href = '/signin'}>
            Log In
          </button>
          <button className="emergency-btn">
            <PhoneIcon className="phone-icon" /> 24H Emergency
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
