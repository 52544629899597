import React from "react";
import Card from "./Card";

import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import FlipIcon from "@mui/icons-material/Flip";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import PsychologyIcon from "@mui/icons-material/Psychology";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import CameraIcon from "@mui/icons-material/Camera";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

import Topbar from "../../Common/Topbar/Topbar";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { tabs } from "../Hospital"
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider, Box, Typography } from "@mui/material";

const Departments = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          {/* Sidebar */}
          <Sidebar tabs={tabs} sx={{ width: "250px", height: "910px" }} />

          {/* Main Content */}
          <Box
            sx={{
              flexGrow: 1,
              marginLeft: "250px",
            }}
          >
            <Topbar />
            <Box
              sx={{
                // marginTop: "95px",
                marginLeft: "50px",
                marginRight: "50px",
              }}
            >
              {/* Main Header */}
              <Typography
                variant="h4"
                align="center"
                sx={{ mb: 10, mt: 5, fontSize: "40px", fontWeight: "900" }}
              >
                DEPARTMENTS
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                  gap: "60px",
                }}
              >
                {/* Add multiple cards here */}
                <Card
                  icon={<NotificationImportantIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Emergency Department
                    </Typography>
                  }
                  description="Learn more about our doctors"
                  linkTo="/dashboard/Hospital/Services"
                />
                <Card
                  icon={<MonitorHeartIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Cardiology
                    </Typography>
                  }
                  description="Learn more about our nurses"
                  linkTo="/dashboard/Hospital/Services"
                />
                <Card
                  icon={<FlipIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Radiology
                    </Typography>
                  }
                  description="Learn more about our surgeons"
                  linkTo="/surgeons"
                />
                <Card
                  icon={<VaccinesIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Pediatrics
                    </Typography>
                  }
                  description="Learn more about our doctors"
                  linkTo="/doctors"
                />
                <Card
                  icon={<CoronavirusIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Oncology
                    </Typography>
                  }
                  description="Learn more about our nurses"
                  linkTo="/nurses"
                />
                <Card
                  icon={<PsychologyIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Neurology
                    </Typography>
                  }
                  description="Learn more about our surgeons"
                  linkTo="/surgeons"
                />
                <Card
                  icon={<AccessibilityIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Orthopedics
                    </Typography>
                  }
                  description="Learn more about our doctors"
                  linkTo="/doctors"
                />
                <Card
                  icon={<PregnantWomanIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Obstetrics and Gynecology
                    </Typography>
                  }
                  description="Learn more about our nurses"
                  linkTo="/nurses"
                />
                <Card
                  icon={<CameraIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Gastroenterology
                    </Typography>
                  }
                  description="Learn more about our surgeons"
                  linkTo="/surgeons"
                />
                <Card
                  icon={<LocalHospitalIcon sx={{ fontSize: "60px" }} />}
                  header={
                    <Typography variant="h3" sx={{ fontSize: "20px" }}>
                      Intensive Care Unit (ICU)
                    </Typography>
                  }
                  description="Learn more about our doctors"
                  linkTo="/doctors"
                />
                {/* Add more cards as needed */}
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Departments;
