import React, { useState, useEffect } from "react";
import "./Book_Appointment.css"; // External CSS for layout
import api from "../../../api"; // Import your API module
import { useAuth } from "../../../AuthContext";

const BookAppointment = ({ onClose, immediate }) => {
  const { setAlert, setAlertMsg, setAlertType } = useAuth();
  const [showForm, setShowForm] = useState(false); // State to toggle form visibility
  const [formData, setFormData] = useState({
    patientName: "",
    email: "",
    phoneNumber: "",
    appoinmentDate: "",
    hospitalName: "",
    doctorName: "",
    description: "",
  });

  const hospitalList = [
    "City Hospital",
    "General Hospital",
    "Healthcare Clinic",
  ];
  const doctorList = ["Dr. Smith", "Dr. John", "Dr. Adams"];

  useEffect(() => {
    if (immediate) {
      setShowForm(true);
    } else {
      const timer = setTimeout(() => {
        setShowForm(true);
      }, 10000); // 10 seconds delay

      return () => clearTimeout(timer);
    }
  }, [immediate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowForm(true);
    }, 1200000); // 20 minutes

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowForm(false);
    if (onClose) onClose();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted:", formData); // Log submitted form data
    try {
      const res = await api.bookAppointment(formData); // Send the data to the backend
      console.log("Appointment booked successfully:", res.data); // You can handle success here
      setAlertMsg(res.data.msg);
      setAlertType("success");
      setAlert(true);

      setShowForm(false); // Close the form after submission
    } catch (error) {
      console.error("Error booking appointment:", error); // Handle error here
      // Optionally, you can show an alert or notification for the user
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      {showForm && (
        <div className="popup-overlay" onClick={handleClosePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={handleClosePopup}>
              X
            </button>
            <div className="appointment-container">
              <div className="image-container">
                <img src="/bookapoint_pic.png" alt="Book Appointment" />
              </div>
              <div className="form-container">
                <h2>Book An Appointment</h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-row">
                    <div className="form-group">
                      <label>Patient Name</label>
                      <input
                        type="text"
                        placeholder="Enter patient name"
                        value={formData.patientName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            patientName: e.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Email ID</label>
                      <input
                        type="email"
                        placeholder="Enter email address"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="tel"
                        placeholder="Enter phone number"
                        value={formData.phoneNumber}
                        onChange={(e) =>
                          setFormData({ ...formData, phoneNumber: e.target.value })
                        }
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Select Date</label>
                      <input
                        type="date"
                        name="date"
                        value={formData.appoinmentDate}
                        min={currentDate} // Restrict past dates
                        onChange={(e) =>
                          setFormData({ ...formData, appoinmentDate: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>Hospital Name</label>
                      <select
                        value={formData.selectedHospital}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            selectedHospital: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="" disabled>
                          Select Hospital Name
                        </option>
                        {hospitalList.map((hospital, index) => (
                          <option key={index} value={hospital}>
                            {hospital}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Doctor Name</label>
                      <select
                        value={formData.selectedDoctor}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            selectedDoctor: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="" disabled>
                          Select Doctor Name
                        </option>
                        {doctorList.map((doctor, index) => (
                          <option key={index} value={doctor}>
                            {doctor}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      placeholder="Describe your symptoms or concerns"
                      value={formData.description}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <button type="submit" className="submit-btn">
                    Book Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookAppointment;
