import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./ContactUs.module.css"; // Import the CSS module
import BookAppointment from "../sections/Appointment_Form"

const ContactUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <section className={styles.appointment}>
        <div className={styles.container}>
          <div className="row">
            <div className="col-lg-12">
              <div className={styles["section-title"]}>
                <h2> Contact Us</h2>
                <h2>We Are Always Ready to Help You</h2>
                <img src="img/section-img.png" alt="#" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12" data-aos="fade-right">
            <BookAppointment />
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-left">
              <div className={styles["appointment-image"]}>
                <img src="img/contact-img.png" alt="#" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
