import React from "react";
import Sidebar from "../Common/Sidebar/Sidebar";
import styles from "../Common/Dashboard.module.css";
import { Outlet } from "react-router-dom";
import Topbar from "../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export const tabs = [
  { title: "Home", redirect: "/dashboard/Hospital" },
  {
    title: "Appointments",
    redirect: "/dashboard/Hospital/Appointments",
  },
  {
    title: "Patient List",
    redirect: "/dashboard/Hospital/Patient-list",
  },
  {
    title: "Departments",
    redirect: "/dashboard/Hospital/Departments",
  },
  {
    title: "Feedback",
    redirect: "/dashboard/Hospital/Feedback",
  },
  {
    title: "Wallet",
    redirect: "/dashboard/Hospital/Wallet",
  },

  // {
  //   title: "Speciality",
  //   redirect: "/dashboard/Hospital/Speciality",
  // },
];

export default function HospitalDash() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
          <div className={styles.container}>
            <Sidebar tabs={tabs} />
            <div className={styles.mainContent}>
              <Topbar />
              <div className={styles.contentArea}>
                <Outlet />
                {"welcome to Hospital Dashboard"}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
