import React from "react";

const About = () => {
  return (
    <div>
      <section className="why-choose section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>
                  Find the right care, doctor, and hospital right at your
                  fingertips
                </h2>
              </div>
            </div>
          </div>
          <div className="choose-right">
            {/* Video Image 1 */}
            <div className="video-image">
              <div className="promo-video">
                <div className="waves-block">
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  <div className="waves wave-3"></div>
                </div>
              </div>
              <img src="img/video-bg.jpg" alt="Video Background" />
              <a
                href="https://www.youtube.com/watch?v=kXLwD7q3HmI"
                className="video video-popup mfp-iframe"
              >
                <i className="fa fa-play"></i>
              </a>
            </div>

            {/* Video Image 2 */}
            <div className="video-image">
              <div className="promo-video">
                <div className="waves-block">
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  <div className="waves wave-3"></div>
                </div>
              </div>
              <img src="/image_vid.png" alt="Video Background" />
              <a
                href="https://www.youtube.com/watch?v=kXLwD7q3HmI"
                className="video video-popup mfp-iframe"
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button>Watch Playlist</button>
      </div>
    </div>
  );
};

export default About;
