import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import api from "../../../../api"; // Import your API module
import styles from './DocListCard.module.css'; // Import the CSS module

export default function DocListCard(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(props.doctor.status || "deactive");
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    navigate({
      pathname: "/dashboard/admin/verify-user/editUser",
      search: `?${createSearchParams({
        uid: props.doctor.uid,
      })}`,
    });
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);

    try {
      setLoading(true);
      const response = await api.statusChange({
        uid: props.doctor.uid,
        status: newStatus,
      });

      console.log("Status updated:", response.data);
    } catch (error) {
      console.error(
        "Error updating status:",
        error.response?.data?.message || error.message
      );
      setStatus(status === "active" ? "deactive" : "active");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={`${styles.card} ${status === "active" ? styles.active : styles.deactive}`} // Apply dynamic classes
    >
      <CardContent className={styles.cardContent}>
        <Box className={styles.statusBox}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={handleStatusChange}
              disabled={loading}
              sx={{ color: "black" }}
            >
              <MenuItem value="active">
                <FiberManualRecordIcon
                  sx={{ fontSize: 12, color: "green", mr: 1 }}
                />
                Active
              </MenuItem>
              <MenuItem value="deactive">
                <FiberManualRecordIcon
                  sx={{ fontSize: 12, color: "red", mr: 1 }}
                />
                Inactive
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <br />

        <Typography variant="h2" color="black" sx={{ mb: 3 }}>
          {`Dr. ${props.doctor.fname} `}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box className={styles.image} sx={{ flex: 1, textAlign: "center" }}>
            <img
              src={props.doctor.imageUrl || "/DoctorImage.png"}
              alt={`Dr. ${props.doctor.fname}`}
              style={{ width: "150px", borderRadius: "8px" }}
            />
          </Box>
          <Box sx={{ flex: 1, textAlign: "left" }}>
            <Typography className={styles.department}>
              {`Department of ${props.doctor.department}`}
            </Typography>
            <Typography className={styles.speciality}>
              {`Speciality: ${props.doctor.speciality}`}
            </Typography>
            <Typography className={styles.clinicName}>
              {`Clinic Name: ${props.doctor.clinicName}`}
            </Typography>
            <Typography variant="subtitle1" className={styles.workDays}>
              {props.doctor.workDays
                .map((dayObj) => `${dayObj.day}: ${dayObj.time}`)
                .join(" | ")}
            </Typography>
          </Box>
        </Box>
        
        <CardActions className={styles.cardActions}>
          <Button
            variant="contained"
            color="warning"
            size="small"
            endIcon={<EditIcon />}
            onClick={handleEdit}
          >
            Edit
          </Button>
          <Button sx={{ pointerEvents: "none", color: "#000" }}>
            {`₹ ${props.doctor.fee}/-`}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
