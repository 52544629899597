import React, { useEffect, useState } from "react";
import styles from "./AddNew.module.css";
import Navbar from "../../../Navbar/Navbar";
import { useSearchParams } from "react-router-dom";
import api from "../../../../api"; // Assuming this is your custom API utility
import { useAuth } from "../../../../AuthContext";
import { departments } from "../../Doctor/doctorDepartments";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { tabs } from "../Admin";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const options = [
  "Select User Type",
  "Diagnostics",
  "Pharmacy",
  "Hospital",
  "Doctor",
  "NGO",
  "Home Care",
  "Laboratory",
  "Ambulance",
  "Patient",
];

export default function AddNew() {
  const [theme, colorMode] = useMode();
  const { setLoader, setAlert, setAlertMsg, setAlertType } = useAuth();
  const [searchParams] = useSearchParams();

  const [userType, setUserType] = useState("");
  const [fname, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [workDays, setWorkDays] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [fee, setFee] = useState("");
  const [department, setDepartment] = useState("");
  const [timeSlots, setTimeSlots] = useState({});
  const [pmcNo, setPmcNo] = useState("");
  const [status, setStatus] = useState("deactive"); // New state for status
  // const [ profilePicture, setProfilePicture] = useState(null);
  // const [profilePreview, setProfilePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = searchParams.get("uid");
        const response = await api.getfindUser(uid);
        const userData = response.data;

        console.log("output from backend:", userData);

        // Populate state with fetched user data
        setUserType(userData.userType || "");
        setFullName(userData.fname || "");
        setEmail(userData.email || "");
        setDepartment(userData.department || "");
        setSpeciality(userData.speciality || "");
        setClinicName(userData.clinicName || "");
        setFee(userData.fee || "");
        setWorkDays(userData.workDays || []);
        setPmcNo(userData.pmcNo || "");
        setMobileNumber(userData.mobileNumber || "");

        // Set initial time slots if available
        const initialTimeSlots = {};
        (userData.workDays || []).forEach((day) => {
          initialTimeSlots[day] = userData.timeSlots[day] || "";
        });
        setTimeSlots(initialTimeSlots);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchParams]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add the day to workDays with an empty time field
      setWorkDays((prevWorkDays) => [
        ...prevWorkDays,
        { day: value, time: "" },
      ]);
    } else {
      // Remove the day from workDays
      setWorkDays((prevWorkDays) =>
        prevWorkDays.filter((dayObj) => dayObj.day !== value)
      );
      // setWorkDays((prevSelectedDays) => prevSelectedDays.filter((day) => day !== value));
      // setTimeSlots((prevSlots) => {
      //   const updatedSlots = { ...prevSlots };
      //   delete updatedSlots[value];
      //   return updatedSlots;
      // });
    }
  };

  const handleTimeSlotChange = (day, event) => {
    const { value } = event.target;
    setWorkDays((prevWorkDays) =>
      prevWorkDays.map((dayObj) =>
        dayObj.day === day ? { ...dayObj, time: value } : dayObj
      )
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uid = searchParams.get("uid");

    const workDaysWithTimes = workDays.map((dayObj) => ({
      day: dayObj.day,
      time: dayObj.time || "",
    }));
    
    try {
      setLoader(true);
      const dataToSend = {
        uid,
        userType, // Include userType
        workDays: workDaysWithTimes,
        email,
        department,
        speciality,
        fee,
      };
  
      // Log the JSON data before sending
      console.log("Data to send:", dataToSend);
      
      const res = await api.verify(dataToSend);
      if (res.data.error) {
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        setAlertMsg(res.data.msg);
        setAlertType("success");
        setAlert(true);
      }
    } catch (error) {
      setAlertMsg(error?.response?.data?.errorMsg || "An Error Occurred!");
      setAlert(true);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sidebar tabs={tabs} />
        {/* {sidebarConfig.tabs.length > 0 ? (
          <Sidebar tabs={sidebarConfig.tabs} />
        ) : (
          <CircularProgress /> // Show loader if sidebar tabs are not yet loaded
        )} */}
        <div>
          <Topbar />
        </div>
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="usertype">User Type</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="usertype"
                name="usertype"
                value={userType}
                disabled
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="fullname">Full Name</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="fullname"
                name="fullname"
                value={fname}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label className={styles.label} htmlFor="mobileNumber">
                Mobile Number
              </label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                value={mobileNumber}
                disabled
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="email">Email</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="pmcNo">PMC No. / Registration No. </label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="pmcNo"
                name="pmcNo"
                value={pmcNo}
                onChange={(e) => setPmcNo(e.target.value)}
              />
            </div>
          </div>

      {userType === "Doctor" && (
        <>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="department">Department</label>
            </div>
            <div className={styles.col75}>
              <select
                className={styles.input}
                id="department"
                name="department"
                value={department}
                onChange={(event) => setDepartment(event.target.value)}
                required
              >
                {departments.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.rrow}>
                  <div className={styles.col25}>
                    <label htmlFor="clinicName">Clinic Name</label>
                  </div>
                  <div className={styles.col75}>
                    <input
                      className={styles.input}
                      type="text"
                      id="clinicName"
                      name="clinicName"
                      value={clinicName}
                      disabled
                    />
                  </div>
                </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="speciality">Speciality</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="speciality"
                name="speciality"
                value={speciality}
                onChange={(event) => setSpeciality(event.target.value)}
                placeholder="Speciality ..."
                autoComplete="off"
                required
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="fee">Fee</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="number"
                id="fee"
                name="fee"
                value={fee}
                onChange={(event) => setFee(event.target.value)}
                placeholder="Appointment fee..."
                autoComplete="off"
                required
              />
            </div>
          </div>
        </>
      )}

          <div className={styles.rrow}>
            <div className={styles.col25}>
              <p className={styles.workdaysLabel}>Work Days</p>
            </div>
            <div className={styles.col75}>
              {days.map((day) => (
                <div key={day} className={styles.checkboxItem}>
                  <label className={styles.label}>
                    <input
                      type="checkbox"
                      value={day}
                      checked={workDays.some(
                        (dayObj) => dayObj.day === day
                      )}
                      onChange={handleCheckboxChange}
                    />
                    {day}
                    {workDays.some((dayObj) => dayObj.day === day) && (
                      <input
                        type="text"
                        className={styles.timeInput}
                        value={
                          workDays.find((dayObj) => dayObj.day === day)
                            ?.time || ""
                        }
                        onChange={(e) => handleTimeSlotChange(day, e)}
                        placeholder="Time (e.g., 9am to 12pm)"
                      />
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.rrow}>
            <button type="submit" className={styles.submitBtn}>
              Verify User
            </button>
          </div>
        </form>
      </div>
    </div>
    </ThemeProvider>
  </ColorModeContext.Provider>
  );
}

