import React, { useState } from "react";
import Card2 from "./Card2"; // Adjust the import path as necessary

import Topbar from "../../Common/Topbar/Topbar";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { tabs } from "../Hospital"
import { ColorModeContext, useMode } from "../../../../theme";
import {
  CssBaseline,
  ThemeProvider,
  Box,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const Services = () => {
  const [theme, colorMode] = useMode();
  const [cards, setCards] = useState([
    {
      id: 1,
      image:
        "https://media.istockphoto.com/id/179011088/photo/indian-doctor.jpg?s=612x612&w=0&k=20&c=EwRn1EWy79prCtdo8yHM6hvCVVcaKTznVBpVURPJxt4=",
      name: "Dr. Daniel Hughes",
      specialization: "Dr. Daniel Hughes",
      experience: "15 years",
      qualifications: "MBBS, General Surgery",
      contact: "123-456-7890",
      availability: "24/7",
      expertise: "Trauma Care",
      languages: "English, Spanish",
      location: "New York, NY",
    },
    {
      id: 2,
      image:
        "https://c8.alamy.com/comp/KX39P9/indian-medical-surgeon-lady-doctor-laptop-working-in-hospital-KX39P9.jpg",
      name: "0",
      specialization: "Dr. Emily Carter",
      experience: "8 years",
      qualifications: "MD, Cardiology",
      contact: "234-567-8901",
      availability: "Mon-Fri, 9 AM - 5 PM",
      expertise: "Heart Diseases",
      languages: "English",
      location: "Los Angeles, CA",
    },
    {
      id: 3,
      image:
        "https://regencyhealthcare.in/wp-content/uploads/2022/08/doctor-banner-Dr.-Narayan-Pratap-Singh-899.png",
      name: "",
      specialization: "Dr. Michael Anderson",
      experience: "5 years",
      qualifications: "MD, Radiology",
      contact: "345-678-9012",
      availability: "Mon-Sat, 8 AM - 4 PM",
      expertise: "Imaging Techniques",
      languages: "English, French",
      location: "Chicago, IL",
    },
    {
      id: 4,
      image:
        "https://thumbs.dreamstime.com/b/indian-female-doctor-22957497.jpg",
      name: "",
      specialization: "Dr. Sarah Patel",
      experience: "7 years",
      qualifications: "MD, Pediatrics",
      contact: "456-789-0123",
      availability: "Mon-Fri, 10 AM - 6 PM",
      expertise: "Child Health",
      languages: "English, Mandarin",
      location: "Houston, TX",
    },
  ]);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [newCardData, setNewCardData] = useState({
    image: "",
    specialization: "",
    experience: "",
    qualifications: "",
    contact: "",
    availability: "",
    expertise: "",
    languages: "",
    location: "",
  });

  const handleEdit = (updatedCard) => {
    setCards((prevCards) =>
      prevCards.map((card) => (card.id === updatedCard.id ? updatedCard : card))
    );
  };

  const handleDeleteOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteConfirmDialog(true);
  };

  const confirmDelete = () => {
    setCards((prevCards) =>
      prevCards.filter((card) => card.id !== selectedCardId)
    );
    setOpenDeleteConfirmDialog(false);
    setSelectedCardId(null);
  };

  const handleCreateOpen = () => {
    setOpenCreateDialog(true);
  };

  const handleCreateClose = () => {
    setOpenCreateDialog(false);
  };

  const handleCreate = () => {
    const newCard = {
      id: cards.length + 1,
      ...newCardData,
    };
    setCards((prevCards) => [...prevCards, newCard]);
    setOpenCreateDialog(false);
    setNewCardData({
      image: "",
      specialization: "",
      experience: "",
      qualifications: "",
      contact: "",
      availability: "",
      expertise: "",
      languages: "",
      location: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCardData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectCardToDelete = (event) => {
    setSelectedCardId(event.target.value);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          {/* Sidebar */}
          <Sidebar tabs={tabs} sx={{ width: "250px", height: "910px" }} />

          {/* Main Content */}
          <Box
            sx={{
              flexGrow: 1,
              marginLeft: "270px",
            }}
          >
            <Topbar />
            <Box
              sx={{
                marginLeft: "50px",
                marginRight: "50px",
              }}
            >
              {/* You can add any additional content here */}
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                gap: "60px",
                marginLeft: "180px",
                marginTop: "125px",
              }}
            >
              {cards.map((card) => (
                <Card2
                  key={card.id}
                  id={card.id}
                  image={card.image}
                  specialization={card.specialization}
                  experience={card.experience}
                  qualifications={card.qualifications}
                  contact={card.contact}
                  availability={card.availability}
                  expertise={card.expertise}
                  languages={card.languages}
                  location={card.location}
                  onEdit={handleEdit}
                />
              ))}
            </Box>
            {/* Floating Action Buttons */}
            <Fab
              color="primary"
              aria-label="add"
              sx={{ position: "fixed", bottom: 16, right: 16 }}
              onClick={handleCreateOpen}
            >
              <AddIcon />
            </Fab>
            <Fab
              color="error"
              aria-label="delete"
              sx={{ position: "fixed", bottom: 80, right: 16 }}
              onClick={handleDeleteOpen}
            >
              <DeleteIcon />
            </Fab>
          </Box>
        </Box>

        {/* Create Card Dialog */}
        <Dialog open={openCreateDialog} onClose={handleCreateClose}>
          <DialogTitle>Create New Card</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="image"
              label="Image URL"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="specialization"
              label="Specialization"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="experience"
              label="Experience"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="qualifications"
              label="Qualifications"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="contact"
              label="Contact"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="availability"
              label="Availability"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="expertise"
              label="Expertise"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="languages"
              label="Languages"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="location"
              label="Location"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateClose}>Cancel</Button>
            <Button onClick={handleCreate}>Create</Button>
          </DialogActions>
        </Dialog>

        {/* Delete Card Dialog */}
        <Dialog open={openDeleteDialog} onClose={handleDeleteClose}>
          <DialogTitle>Select Card to Delete</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="delete-card-select-label">Select Card</InputLabel>
              <Select
                labelId="delete-card-select-label"
                id="delete-card-select"
                value={selectedCardId || ""}
                label="Select Card"
                onChange={handleSelectCardToDelete}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {cards.map((card) => (
                  <MenuItem key={card.id} value={card.id}>
                    {card.specialization}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={openDeleteConfirmDialog}
          onClose={() => setOpenDeleteConfirmDialog(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this card?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirmDialog(false)}>
              No
            </Button>
            <Button onClick={confirmDelete} color="error">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Services;
