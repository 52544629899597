export const departments = [
  "All Departments",
  "Cardiology",
  "Dermatology",
  "ENT",
  "Gastroenterology",
  "General Medicine",
  "General Surgery",
  "Neurology",
  "Paediatrics",
  "Urology",
  "Dental",
  "General Physician",
];
