import React from "react";
import Sidebar from "../Common/Sidebar/Sidebar";
import styles from "../Common/Dashboard.module.css";
import { Outlet } from "react-router-dom";
import Topbar from "../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export const tabs = [
  { title: "Home", redirect: "/dashboard/Home Care" },
  { title: "HomeCare Services", redirect: "/dashboard/Home Care/serives" },
  {
    title: "HomeCare Booking and Billing",
    redirect: "/dashboard/Home Care/booking",
  },
  {
    title: "HomeCare Notification",
    redirect: "/dashboard/Home Care/notification",
  },
  {
    title: "HomeCare Packages and Offers",
    redirect: "/dashboard/Home Care/packages",
  },
  {
    title: "HomeCare Prescriptions",
    redirect: "/dashboard/Home Care/prescription",
  },
  { title: "HomeCare Reports", redirect: "/dashboard/Home Care/report" },
];

export default function HomeCareDash() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
          <div className={styles.container}>
            <Sidebar tabs={tabs} />
            <div className={styles.mainContent}>
              <Topbar />
              <div className={styles.contentArea}>
                <Outlet />
                {"welcome to Home Care Dashboard"}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
