import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../AuthContext";
import styles from "./SignIn.module.css";
import { FaGoogle } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

export default function SignIn() {
  const { setUserType, setLoader, setAlert, setAlertMsg } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleGoogleLogin = () => {
    // Add your Google login logic here
    console.log("Logging in with Google...");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const postData = { email, password };

    try {
      setLoader(true);
      const res = await api.signin(postData);

      if (res.data.error) {
        setLoader(false);
        setPassword("");
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        setLoader(false);
        setEmail("");
        setPassword("");
        const loggedUser = res.data.userType;
        const { accessToken, refreshToken } = res.data;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        setUserType(loggedUser);
      }
    } catch (error) {
      setLoader(false);
      setPassword("");
      setAlertMsg(error?.response?.data?.errorMsg || "An Error Occured!");
      setAlert(true);
      console.error(error);
    }
  };
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          {/* left Column */}
          <div
            className={styles.col}
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              src="/signIn.png"
              alt="signin"
              className={styles.image}
              draggable="false"
            />
          </div>
          {/* Right Column */}
          <div className={styles.col}>
            <div className={styles.maincontainer}>
              <h1 className={styles.header} data-aos="zoom-in">
                Welcome Back
              </h1>
              <h6 className={styles.subHeader} data-aos="zoom-in">
                Discover a better way of spandings with Uifry.
              </h6>
              <div data-aos="zoom-in">
                <button
                  className={styles.googleLoginBtn}
                  onClick={handleGoogleLogin}
                >
                  <FaGoogle className={styles.googleIcon} />
                  Log in with Google
                </button>
              </div>

              <div className={styles.separator}>
                <span className={styles.separatorText}>or</span>
              </div>

              <form className={styles.form} onSubmit={handleSubmit} data-aos="fade-left">
                <div className={styles.formGroup} >
                  <input
                    required
                    id="email"
                    type="text"
                    value={email}
                    autoComplete="email"
                    autoFocus
                    placeholder="Enter your email or phone Number"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>

                <div className={styles.formGroup} >
                  <input
                    required
                    name="password"
                    type="password"
                    id="password"
                    value={password}
                    placeholder="Enter the Password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                {/* Row for "Remember me" checkbox and "Forgot password" link */}
                <div className={styles.rememberForgotRow} >
                  <div className={styles.rememberMe}>
                    <input type="checkbox" id="rememberMe" />
                    <label htmlFor="rememberMe">Remember me</label>
                  </div>
                  <Link to="/forgot-password" className={styles.forgotPassword}>
                    Forgot password?
                  </Link>
                </div>

                <button
                  type="submit"
                  className={styles.submitButton}
                >
                  Sign In
                </button>

                <Link to="/signup" className={styles.linkBtn}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
