// DocFeedbacks.js
import React from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { mockDataFeedbacks } from "./mockdatafeedback"; // Ensure this import is correct
import Header from "../../Common/Heading/Heading";
import styles from "./HospitalFeedbacks.module.css";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { tabs } from "../Hospital"
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import StarHalf from "@mui/icons-material/StarHalf";

const StarRating = ({ rating }) => {
  const maxRating = 5;
  const filledStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = maxRating - filledStars - halfStar;

  return (
    <Box display="flex" alignItems="center">
      {Array.from({ length: filledStars }, (_, index) => (
        <Star key={`filled-${index}`} style={{ color: "#FFD700" }} />
      ))}
      {halfStar === 1 && <StarHalf style={{ color: "#FFD700" }} />}
      {Array.from({ length: emptyStars }, (_, index) => (
        <StarBorder key={`empty-${index}`} style={{ color: "#FFD700" }} />
      ))}
    </Box>
  );
};

const DocFeedbacks = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "Feedback ID", flex: 0.5 },
    { field: "patientName", headerName: "Patient Name", flex: 1 },
    {
      field: "rating",
      headerName: "Ratings (Scale of 5)",
      flex: 1,
      renderCell: (params) => <StarRating rating={params.value} />,
    },
    { field: "comments", headerName: "Comments", flex: 2 },
    { field: "feedbackDate", headerName: "Date of Feedback", flex: 1 },
    { field: "appointmentDate", headerName: "Appointment Date", flex: 1 },
    { field: "serviceType", headerName: "Service Type", flex: 1 },
    {
      field: "doctorResponse",
      headerName: "Doctor Response",
      flex: 1.5,
      renderCell: (params) => (params.value ? params.value : "No response yet"),
    },
    { field: "feedbackStatus", headerName: "Feedback Status", flex: 1 },
    { field: "patientAgeGroup", headerName: "Patient Age Group", flex: 1 },
    { field: "severity", headerName: "Severity", flex: 1 },
    {
      field: "followUpRequired",
      headerName: "Follow-Up Required",
      flex: 1,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.container}>
          <Sidebar tabs={tabs} />
          <div className={styles.mainContent}>
            <Topbar />
          </div>
          <Box m="20px" marginLeft="285px">
            {/* Wrapped the Header in a Box for better styling and control */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mb={2}
            >
              <Header
                title="Patient Feedbacks"
                subtitle="List of All Feedbacks and Their Details"
              />
            </Box>
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#1a385a",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#1a385a",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={mockDataFeedbacks}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default DocFeedbacks;
