// mockdata.js

const mockServices = [
    {
      id: 1,
      name: "General Checkup",
      description: "Routine health checkup including basic diagnostics.",
      price: "50",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 2,
      name: "Dental Cleaning",
      description: "Comprehensive dental cleaning service.",
      price: "75",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 3,
      name: "Eye Examination",
      description: "Complete eye health and vision exam.",
      price: "40",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 4,
      name: "Physiotherapy Session",
      description: "Therapeutic exercises and manual therapy techniques.",
      price: "60",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 5,
      name: "Nutrition Consultation",
      description: "Personalized diet and nutrition guidance.",
      price: "30",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 6,
      name: "Pediatric Care",
      description: "Health services for children from birth to adolescence.",
      price: "80",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 7,
      name: "Orthopedic Assessment",
      description: "Assessment and treatment of musculoskeletal conditions.",
      price: "100",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 8,
      name: "Cardiology Consultation",
      description: "Heart health evaluation and management.",
      price: "120",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 9,
      name: "Dermatology Visit",
      description: "Skin health evaluation and treatment.",
      price: "90",
      image: "https://via.placeholder.com/150",
    },
  ];
  
  export default mockServices;
  