import React from "react";
import Sidebar from "../Common/Sidebar/Sidebar";
import styles from "../Common/Dashboard.module.css";
import { Outlet } from "react-router-dom";
import Topbar from "../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export const tabs = [
  { title: "Home", redirect: "/dashboard/Pharmacy" },
];


export default function PharmacyDash() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
          <div className={styles.container}>
            <Sidebar tabs={tabs} />
            <div className={styles.mainContent}>
              <Topbar />
              <div className={styles.contentArea}>
                <Outlet />
                {"welcome to Pharmacy Dashboard"}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
