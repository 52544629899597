import React from "react";
import AboutUs from "./aboutUs";
import Header from "../sections/Header";
import Footer from "../sections/Footer";

function Index() {

  return (
    <div>
      <Header />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default Index;


// import React from "react";
// // import About from "../sections/About";
// import AboutUs from "./aboutUs"
// import ContactUs from "../sections/ContactUs";
// import ServicesTwo from "../sections/ServicesTwo";
// import Header from "../sections/Header";
// import Footer from "../sections/Footer";

// function index() {
//   return (
//     <div>
//       <Header />
//       <AboutUs/>
//       {/* <About />
//       <ServicesTwo />
//       <ContactUs /> */}
//       <Footer />
//     </div>
//   );
// }
// export default index;
