import React from "react";
import Contact from "./contactUs";
import Header from "../sections/Header";
import Footer from "../sections/Footer";

const ContactUsPage = () => {
  return (
    <div>
      <Header />
      <Contact />
      {/* <ContactUs />
      <About /> */}
      <Footer />
    </div>
  );
};

export default ContactUsPage;
