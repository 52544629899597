// services.js

import React, { useState } from "react";
import styles from "./Service.module.css";
import mockServices from "./mockData";
import { tabs } from "../Doctor";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const Services = () => {
  const [theme, colorMode] = useMode();
  const [services, setServices] = useState(mockServices);
  const [selectedService, setSelectedService] = useState(null);
  const [formData, setFormData] = useState({ id: null, name: "", description: "", price: "", image: "" });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOpenDetails = (service) => {
    setSelectedService(service);
    setIsCollapsed(false);
  };

  const handleOpenDialog = (service = { id: null, name: "", description: "", price: "", image: "" }) => {
    setFormData(service);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setFormData({ id: null, name: "", description: "", price: "", image: "" });
  };

  const handleEdit = () => {
    const updatedServices = services.map((service) =>
      service.id === formData.id ? formData : service
    );
    setServices(updatedServices);
    setSelectedService(formData);
    handleCloseDialog();
  };

  const handleDelete = () => {
    setServices(services.filter((service) => service.id !== formData.id));
    setSelectedService(null);
    handleCloseDialog();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.id) {
      handleEdit();
    } else {
      setServices([...services, { ...formData, id: Date.now() }]);
      handleCloseDialog();
    }
  };

  const handleCollapseDetails = () => {
    setSelectedService(null);
    setIsCollapsed(true);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sidebar tabs={tabs} />
        <div className={styles.mainContent}>
          <Topbar />
          <div className={styles.container}>
            {/* Top action button container */}
            <div className={styles.topActions}>
              <button
                className={`${styles.button} ${styles.addButton}`}
                onClick={() => handleOpenDialog()}
              >
                Add Service
              </button>
            </div>

            {/* Main layout containing the cards and details */}
            <div className={styles.selectedLayout} style={{ width: isCollapsed ? "100%" : undefined }}>
              <div className={selectedService ? styles.columnContainer : styles.cardContainer}>
                {services.map((service) => (
                  <div
                    key={service.id}
                    className={`${styles.card} ${selectedService ? styles.columnCard : ""}`}
                    onClick={() => handleOpenDetails(service)}
                  >
                    <img src={service.image} alt={service.name} />
                    <div className={styles.cardContent}>
                      <h3 className={styles.cardName}>{service.name}</h3>
                      <p className={styles.cardDescription}>{service.description}</p>
                      <p className={styles.cardPrice}>${service.price}</p>
                    </div>
                  </div>
                ))}
              </div>

              {!isCollapsed && selectedService && (
                <div className={styles.detailsContainer}>
                  <div className={styles.detailsHeader}>
                    <button className={styles.collapseButton} onClick={handleCollapseDetails}>
                      Collapse
                    </button>
                  </div>
                  <div className={styles.detailsCard}>
                    <img src={selectedService.image} alt={selectedService.name} className={styles.detailsImage} />
                    <h2 className={styles.detailsName}>{selectedService.name}</h2>
                    <p className={styles.detailsDescription}>{selectedService.description}</p>
                    <p className={styles.detailsPrice}>${selectedService.price}</p>
                    <div className={styles.actionButtons}>
                      <button
                        className={`${styles.button} ${styles.editButton}`}
                        onClick={() => handleOpenDialog(selectedService)}
                      >
                        Edit
                      </button>
                      <button
                        className={`${styles.button} ${styles.deleteButton}`}
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Dialog for adding and editing services */}
            {isDialogOpen && (
              <dialog open={isDialogOpen} className={styles.dialog}>
                <form onSubmit={handleSubmit} className={styles.form}>
                  <h2>{formData.id ? "Edit Service" : "Add Service"}</h2>
                  <input
                    type="text"
                    name="name"
                    placeholder="Service Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <textarea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="image"
                    placeholder="Image URL"
                    value={formData.image}
                    onChange={handleChange}
                    required
                  />
                  <div className={styles.dialogButtons}>
                    <button type="submit" className={`${styles.button} ${styles.saveButton}`}>
                      {formData.id ? "Update" : "Add"}
                    </button>
                    <button
                      type="button"
                      className={`${styles.button} ${styles.cancelButton}`}
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </dialog>
            )}
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Services;
