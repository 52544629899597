import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../../../api"; // Assuming this is your custom API utility
import { useAuth } from "../../../../AuthContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { tabs } from "../Admin";
import EditUserForm from "../../Common/EditUserForm/EditUserForm";
import { useLocation } from "react-router-dom";


export default function EditUser() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sidebar tabs={tabs} />
        {/* {sidebarConfig.tabs.length > 0 ? (
          <Sidebar tabs={sidebarConfig.tabs} />
        ) : (
          <CircularProgress /> // Show loader if sidebar tabs are not yet loaded
        )} */}
        <div>
          <Topbar />
        </div>
        <EditUserForm uid={uid}/>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
